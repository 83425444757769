<template>
	<div class="footer" >
		<div class="footer-icon-container">
			<router-link :to="{ name: 'dashboard' }" class="link dashboard">
				<span class="icon" v-html="homeIcon" />
			</router-link>

			<router-link :to="{ name: 'messages' }" class="link messages" :class="{'active' : currentRoute === 'message-single'}">
				<span class="icon" v-html="bellIcon" />
				<span class="count"
					:class="{'many-notifications': notificationsCount > 99}"
					v-if="notificationsCount > 0"
				>
					{{ notificationsCount > 99 ? '99+' : notificationsCount }}
				</span>
			</router-link>

			<div class="footer-line"></div>

			<router-link :to="{ name: 'help' }" class="link help" :class="{'empty': !helpPageExists}">
				<span class="icon" v-html="questionMarkIcon" />
			</router-link>

			<router-link :to="{ name: 'qr-scanner' }" class="link scanner" v-if="buttonPermissions.includes('qr-code-scanner')">
				<span class="icon" v-html="qrIcon" />
			</router-link>
			<div style="width:27px" v-else></div>
		</div>
	</div>
</template>

<script setup>
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'
	import { computed, nextTick, onBeforeMount, onMounted, watch } from 'vue'

	import {
		homeIcon,
		bellIcon,
		questionMarkIcon,
		qrIcon
	} from '@/utils/svgIcons'

	const store = useStore()
	const route = useRoute()

	const notificationsCount = computed(() => store.state.messages.count)
	const currentRoute = computed(() => route.name)
	const buttonPermissions = computed(() => store.state.user.buttonPermissions)
	const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)
	const helpPageExists = computed(() => store.state.help.helpPageHTML != '')


	// Trigger the help page logic at the start here, and then again on every route change
	function setHelpData() {
		if (route.fullPath != '/login') {
			store.commit('setLastAccessedRoute', route.fullPath)
			store.dispatch('help/fetchHelpPageHTML', encodeURIComponent(route.fullPath))
		}
	}


	// Before mount, fetch notifications count
	onBeforeMount(() => {
		if (store.state.userLoggedIn) {
			store.dispatch('messages/fetchNotificationsCount')
		}
	})

	onMounted(() => {
		if (!store.state.help.fetchedDashboardHelp) {
			nextTick(() => setHelpData())
		}

		watch(currentRoute, () => {
			if (currentRoute.value != 'help') nextTick(() => setHelpData())
		})
	})
</script>

<style lang="sass">
.footer
	position: fixed
	bottom: 0
	display: flex
	align-items: center
	height: 51px
	width: 100%
	min-width: 250px
	max-width: 900px
	background-color: $white
	box-shadow: 0 3px 6px #01000029
	z-index: 9999

.footer-icon-container
	display: flex
	align-items: center
	justify-content: space-evenly
	margin: 0 auto
	width: 100%
	min-width: 250px
	max-width: 350px

	.link
		height: 28px

		.icon
			svg
				height: 27px
				width: auto

		&.router-link-active
			.icon
				svg
					path
						fill: $green !important

		&.messages
			position: relative

			.icon
				svg
					height: 28px
					width: auto

			.count
				top: -3px
				right: -12px
				position: absolute

				background: $red
				border-radius: 100%

				width: 23px
				height: 23px

				text-align: center

				font-size: 14px
				color: $white
				font-family: 'PoppinsRegular'
				line-height: 23px
				letter-spacing: 0

				&.many-notifications
					font-size: 12px

			&.router-link-active,
			&.active // Message single
				.icon
					svg
						path
							fill: $green !important

		&.help
			&.router-link-active
				pointer-events: none

			&.empty
				display: none

	.footer-line
		width: 38%
		max-width: 140px
		min-width: 70px

	.footer-icon
		height: 27px
		cursor: pointer

.dark-mode
	.footer
		box-shadow: 0px 0 3px rgba(0, 0, 0, 0.8)
		background-color: $text-black
</style>
