<template>
    <div class="delivery-incoming-page">
        <Header :pageTitle="pageTitle" />
        <SubHeader 
            :currentPage="currentPage"
            :showFilters="true"
			:filterAction="'deliveryItems/fetchIncomingDeliveries'"
			:filterRoute="'filters/fetchIncomingDeliveryFilterItems'"
            :subFilter="true"
            :parameterName="'contract_number'"
            :parameterValue="store.state.contracts.selectedContract.contract_number"
            @filtersParameter="getFiltersParameter"
            :pageName="'incoming'"
            :filtersDisabled="!(deliveryItems.length > 0) && selectedFilters == ''"
        />

        <Loader v-if="isRequesting" />
        <div v-else>
            <div class="delivery-items-container filter-parent-container" v-if="deliveryItems.length > 0">
                <DeliveryCard
                    v-for="item in deliveryItems" 
                    :key="item.id"

                    :id="item.id"
                    :status="item.status"
                    :deliveryNoteNumber="item.number"
                    :plateData="item.vehicle_license_plate"
                    :supplierName="item.spedition_name"
                    :factoryName="item.factory_name"
                    :factoryNumber="item.factory_number"
                    :confirmedTime="item.confirmed_time"
                    :weighingTime="item.weighing_time"
                    :materials="item.materials"
                />
            </div>
            <div class="delivery-items-container empty" v-else>
                <span class="empty-title">{{'on_site.incoming_deliveries.empty'.trans()}}</span>
                <div class="empty-screen-icon-container">
                    <img class="truck" src="@/assets/icons/truck.svg" alt="empty_deliveries"/>
                    <img class="close" src="@/assets/icons/x-bright-red.svg" alt="X"/>
                </div>
               <span class="empty-message">{{'on_site.incoming_deliveries.empty_message'.trans()}}</span>
            </div>
        </div>

        <Loader v-if="isRequestingPaginated" class="small" />

		<Footer/>
    </div>
</template>

<script setup>
    import Header from '@/components/Common/Header.vue'
    import SubHeader from '@/components/SubHeader/SubHeader.vue'
    import Loader from '@/components/Common/Loader.vue'
	import DeliveryCard from '@/components/ContractDetails/DeliveryCard.vue'
    import Footer from '@/components/Common/Footer.vue'
    import { computed, onMounted, onUnmounted } from 'vue'
    import { useStore } from 'vuex'

    const store = useStore()
    const pageTitle = computed(() => store.state.contracts.selectedContract.contract_name)
    const currentPage = 'on_site.menu.deliveries_in_progress'.trans()
    
    // The request for data was fired in the route guard
    const deliveryItems = computed(() => store.state.deliveryItems.incomingDeliveryItems.items)
    const pagination = computed(() => store.state.deliveryItems.incomingDeliveryItems.pagination)

    // Loading flags
	const isRequesting = computed(() => store.state.deliveryItems.isDeliveryItemsRequesting)
    const isRequestingPaginated = computed(() => store.state.deliveryItems.isDeliveryItemsPaginationRequesting)

    // Logic for enabling filters when there are no items
    const selectedFilters = computed(() => store.state.filters.selectedFilters)

    // Filters parameter from filters popup - used for pagination
	let filtersParameter = ''
	function getFiltersParameter(val) {
		const openFiltersButton = $('.open-filters-popup')
		filtersParameter = val
	}

    // Pagination logic
	const initializeInfiniteScroll = () => {
		window.onscroll = () => {
            const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight

            if (bottomOfWindow && !isRequesting.value && !isRequestingPaginated.value) {
                const { current_page, page_size, total_items, total_pages, has_next_page } = pagination.value

				if (has_next_page && !$('.filter-parent-container').hasClass('hidden')) {
                    store.dispatch('deliveryItems/fetchIncomingDeliveries', {
                        contract_number: store.state.contracts.selectedContract.contract_number,
                        'filterString': filtersParameter,
                        page: current_page + 1,
                        page_size: page_size,
                    })
				}
			}
		}
    }
    
    onMounted(() => {
		initializeInfiniteScroll()
	})

    onUnmounted(() => {
        store.commit('filters/setSelectedFilters', '')
    })
</script>

<style lang="sass" scoped>
.delivery-incoming-page
	padding-top: 51px + 48px + 23px // header + subheader + margin from design
	padding-bottom: 81px // footer + some extra height
	min-height: calc(100vh - 51px - 48px - 81px)

	.delivery-items-container
		display: flex
		justify-content: space-evenly
		flex-wrap: wrap

		&.empty
			display: flex
			flex-direction: column
			font-family: PoppinsMedium
			font-size: 12px
			color: $text-gray
			text-align: center

			.empty-screen-icon-container
				position: relative
				margin: 70px auto 30px auto
				width: 231px

				.close
					position: absolute
					top: -4px
					right: 15px

			.empty-title
				font-family: PoppinsBold
				font-size: 16px

		&.hidden
			display: none
</style>