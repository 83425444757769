<template>
	<div class="construction-site-page">
		<Header :pageTitle="pageTitle" />
		<SubHeader :showCompanySelect="true" />

		<Loader v-show="isRequesting" />
		<div class="construction-site-container" v-show="!isRequesting">
			<div class="filters-container">
				<SearchFilter
					:searchAction="'contracts/fetchContractsOnLoad'"
					:filtersRoute="'filters/fetchContractFilterItems'"
					@queryParameter="getQueryParameter"
					:parameterName="'company_number'"
					:parameterValue="companyNumberParam"
				/>
				<OverviewFilters
					:key="companyNumberParam"
					:filterAction="'contracts/fetchContractsOnLoad'"
					:filtersRoute="'filters/fetchContractFilterItems'"
					@filtersParameter="getFiltersParameter"
					:parameterName="'company_number'"
					:parameterValue="companyNumberParam"
				/>
			</div>
			<div class="contracts-container filter-parent-container" v-if="hasResults">
				<ContractCard
					v-for="item in items"
					:key="`item-${item.id}`"
					:contractId="item.id"
					:contractName="item.name"
					:contractNumber="item.contract_number"
					:constructionSiteNumber="item.building_site_number"
					:customerNumber="item.customer_order_number"
					:startDate="item.contract_date_start"
					:endDate="item.contract_date_end"
					:supplierName="item.vk_org"
					:favorite="item.favorite"
					:todayDelivery="item.today_delivery"
					:todayCallOff="item.today_call_off"
				/>
			</div>
			<div class="no-results" v-else-if="!hasResults && selectedFilters == '' && selectedQuery == ''">
				<span class="empty-title">{{ 'on_site.no_contract_items'.trans() }}</span>
                <div class="empty-screen-icon-container">
                    <img class="amount" src="@/assets/icons/contracts-empty.svg" alt="empty_amount"/>
                </div>
               <span class="empty-message">{{ 'on_site.no_contract_items_message'.trans() }}</span>
			</div>

			<div class="no-results" v-else>
				<span class="empty-title">{{ 'on_site.no_contract_items_filtered'.trans() }}</span>
                <div class="empty-screen-icon-container">
                    <img class="amount" src="@/assets/icons/contracts-empty.svg" alt="empty_amount"/>
                </div>
               <span class="empty-message">{{ 'on_site.no_contract_items_message_filtered'.trans() }}</span>
			</div>
		</div>

		<Loader v-if="isRequestingPaginated" class="small" />
		<Footer/>
	</div>
</template>

<script setup>
	import { computed, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'

	import Header from '@/components/Common/Header.vue'
	import SubHeader from '@/components/SubHeader/SubHeader.vue'
	import SearchFilter from '@/components/Common/SearchFilter.vue'
	import Footer from '@/components/Common/Footer.vue'
	import ContractCard from '@/components/Dashboard/ContractCard.vue'
	import Loader from '@/components/Common/Loader.vue'
	import OverviewFilters from '@/components/Common/OverviewFilters.vue'

	const pageTitle = 'on_site.page-title.construction-site'.trans()
	const store = useStore()

	// Get contracts
	const items = computed(() => store.state.contracts.contracts.items)

	// Pagination and has results flag
	const hasResults = computed(() => store.state.contracts.contracts && store.state.contracts.contracts.items && store.state.contracts.contracts.items.length > 0)
	const pagination = computed(() => store.state.contracts.contracts.pagination)

	// Loading flags
	const isRequesting = computed(() => store.state.contracts.isContractsRequesting)
	const isRequestingPaginated = computed(() => store.state.contracts.isContractsPaginationRequesting)

	// Company number parameter
	const companyNumberParam = computed(() => store.state.user.selectedCompany.company_nr)

	// Query parameter from search component that needs to be put into pagination for loading more results
	let searchQueryParameter = ''
	function getQueryParameter(val) {
		searchQueryParameter = val
	}
	
	// Filters parameter from filters popup - used for pagination
	let filtersParameter = ''
	function getFiltersParameter(val) {
		filtersParameter = val
	}

	const selectedFilters = computed(() => store.state.filters.selectedFilters)
	const selectedQuery = computed(() => store.state.filters.selectedQuery)

	// Pagination logic
	const initializeInfiniteScroll = () => {
		window.onscroll = () => {
			const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
			if (bottomOfWindow && !isRequesting.value && !isRequestingPaginated.value) {
				const { current_page, page_size, total_items, total_pages, has_next_page } = pagination.value
				if (has_next_page && !$('.contracts-container').hasClass('hidden')) {
					if(searchQueryParameter != '') {
						store.dispatch('contracts/fetchContractsPaginated', {
							company_number: companyNumberParam.value,
							page: current_page + 1,
							page_size: page_size,
							query: searchQueryParameter,
							'filterString': filtersParameter
						})
					} else {
						store.dispatch('contracts/fetchContractsPaginated', {
							company_number: companyNumberParam.value,
							'filterString': filtersParameter,
							page: current_page + 1,
							page_size: page_size,
						})
					}
				}
			}
		}
	}

	onMounted(() => {
		initializeInfiniteScroll()
	})

	onUnmounted(() => {
        store.commit('filters/setSelectedFilters', '')
        store.commit('filters/setSelectedQuery', '')
    })
</script>

<style lang="sass" scoped>
.construction-site-page
	padding-top: 51px + 48px + 11px // header + subheader + margin from design
	padding-bottom: 81px // footer + some extra height
	min-height: calc(100vh - 51px - 48px - 81px)

.construction-site-container
	display: flex
	flex-direction: column
	justify-content: space-evenly
	position: relative

	padding-bottom: 60px

	.filters-container
		display: flex
		justify-content: space-between
		margin: 12px auto 5px auto
		width: 94%

	.contracts-container
		display: flex
		justify-content: space-evenly
		flex-wrap: wrap

	.filter-parent-container
		&.hidden
			display: none

	.no-results
		margin-top: 20px
		display: flex
		flex-direction: column
		font-family: PoppinsMedium
		font-size: 12px
		color: $text-gray
		text-align: center

		.empty-screen-icon-container
			position: relative
			margin: 40px auto 30px auto
			width: 231px

		.empty-title
			font-family: PoppinsBold
			font-size: 16px

	&.hidden
		display: none
</style>