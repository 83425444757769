<template>
    <div class="sub-header-company-select">
        <Select2 class="company-select" :options="availableCompaniesFormatted" :settings="companySelectSettings" v-model="selectedCompany" @select="updateSelectedCompany"/>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'
    import Select2 from '@/components/Select2'

    const store = useStore()
    const route = useRoute()

    const selectedCompany = ref(store.state.user.selectedCompany.company_nr.toString())
    const availableCompanies = store.state.user.data.available_companies

    const availableCompaniesFormatted = availableCompanies.map(object => ({
        'id': object.company_nr,
        'company_name': object.company_name,
        'text': `${object.company_name} (${object.company_nr})`
    }))

    const companySelectSettings = {
        dropdownCssClass: 'sub-header-company-select-dropdown',
        minimumResultsForSearch: -1
    }

    function updateSelectedCompany(event) {
        store.commit('user/setSelectedCompany', {'company_name': event.company_name, 'company_nr': event.id})
        store.dispatch('user/fetchButtonPermissions')

        // for construction-site, get items and get filters
        if (route.name == 'construction-site') {
            store.dispatch('contracts/fetchContractsOnLoad')

        // currently its only dashboard
        } else {
            store.dispatch('contracts/fetchRecentContracts')
        }

        // Logic for reseting search and filters term and reseting the search bar
        store.commit('filters/setSelectedFilters', '')
        store.commit('filters/setSelectedQuery', '')

        $('#contract-overview-select').val(null).trigger('change')
        $('#contract-overview-select').empty().trigger("change")
        $('.select-filter-reset').addClass('hidden')
        $('#contract-overview-select').attr('disabled', false)

        // Show contracts(remove hidden class) when filters were open on company change
        $('.filter-parent-container').removeClass('hidden')
    }
</script>

<style lang="sass" scoped>
.sub-header-company-select
    height: 100%

    .company-select
        display: flex
        align-items: center
        height: 100%
        padding-left: 24px
        padding-right: 24px
</style>

<style lang="sass">
.sub-header-company-select
    .company-select
        .select2-container
            width: 100% !important

            .select2-selection
                border: none

                .select2-selection__rendered
                    padding-top: 1px
                    padding-left: 10px
                    font-family: PoppinsMedium
                    font-size: 12px
                    text-align: left
                    color: $text-blue

                .select2-selection__arrow
                    right: 5px
                    background-image: url(@/assets/icons/arrow-blue.svg)
                    background-repeat: no-repeat
                    background-position: 10px 8px
                    transition: all 0.1s

                    b
                        border: none

            &.select2-container--open
                .select2-selection__arrow
                    transform: rotate(90deg)
                    background-position: 8px 8px
                    transition: all 0.1s

.sub-header-company-select-dropdown
    border-color: $button-gray
    filter: drop-shadow(0px 3px 3px #01000029)
    z-index: 9997

    .select2-results__option
        padding-left: 10px
        padding-bottom: 5px
        font-family: PoppinsMedium
        font-size: 12px
        color: $text-blue

        &--highlighted[aria-selected]
            background-color: $text-blue
</style>