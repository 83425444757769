<template>
    <div class="step-card">
        <div class="calloff-data">
            <table class="calloff-data-table">
                <tr>
                    <td class="data-name">{{'on_site.calloff_creation.incoterm'.trans()}}:</td>
                    <td class="data">{{incoterm}}</td>
                </tr>
                <tr>
                    <td class="data-name">{{'on_site.calloff_creation.vehicle'.trans()}}:</td>
                    <td class="data">{{vehicle}}</td>
                </tr>
                <tr>
                    <td  class="data-name">{{'on_site.calloff_creation.quantity'.trans()}}:</td>
                    <td class="data">{{quantity}}</td>
                </tr>
                <tr>
                    <td class="data-name">{{'on_site.calloff_creation.quantity_hourly'.trans()}}:</td>
                    <td class="data">{{hourly}}</td>
                </tr>
                <tr>
                    <td  class="data-name note">{{'on_site.calloff_creation.note'.trans()}}:</td>
                    <td class="data">{{note ? note : ''}}</td>
                </tr>
                <tr>
                    <td class="data-name">{{'on_site.calloff_creation.night_shift'.trans()}}:</td>
                    <td class="data night-shift">
                        <div class="moon-icon-container" :class="{'active': nightShift}" v-html="moonIcon"></div>
                    </td>
                </tr>
                <tr class="delivery">
                    <td  class="data-name">{{'on_site.calloff_creation.delivery_start'.trans()}}:</td>
                    <td class="data">
                        <img alt=" " src="../../assets/icons/calendar-blue.svg"/>
                        <span>{{dateStart}}</span>
                    </td>
                </tr>
                <tr class="delivery">
                    <td  class="data-name"></td>
                    <td class="data clock">
                        <img alt=" " src="../../assets/icons/clock.svg"/>
                        <span>{{timeStart}}</span>
                    </td>
                </tr>
                <tr class="delivery">
                    <td class="data-name">{{'on_site.calloff_creation.delivery_end'.trans()}}:</td>
                    <td class="data">
                        <img alt=" " src="../../assets/icons/calendar-blue.svg"/>
                        <span>{{dateEnd}}</span>
                    </td>
                </tr>
                <tr class="delivery">
                    <td class="data-name"></td>
                    <td class="data clock">
                        <img alt=" " src="../../assets/icons/clock.svg"/>
                        <span>{{timeEnd}}</span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="button-container">
            <button class="change-calloff-button" @click="goToStepOne">
                {{'on_site.calloff_creation.change_calloff'.trans()}}
            </button>
            <button class="confirm-button" @click="createCalloff">
                {{'on_site.calloff_creation.confirm_calloff'.trans()}}
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute, useRouter } from 'vue-router'
    import { moonIcon } from '@/utils/svgIcons'
    import { makeRequest } from '@/utils/makeRequest'
    import Swal from 'sweetalert2'

    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emit = defineEmits(['changeStep'])

    const cartId = ref(store.state.calloffCreation.data.id)
    const incoterm = ref(store.state.calloffCreation.data.incoterm.title)
    const vehicle = ref(store.state.calloffCreation.data.truck_type.title)
    const quantity = ref(store.state.calloffCreation.data.cart_item_quantity)
    const hourly = ref(store.state.calloffCreation.data.hourly_amount)
    const note = ref(store.state.calloffCreation.data.note)
    const nightShift = ref(store.state.calloffCreation.data.night_shift)
    const dateStart = ref(store.state.calloffCreation.data.date_start)
    const dateEnd = ref(store.state.calloffCreation.data.date_end)
    const timeStart = ref(store.state.calloffCreation.data.date_start_time)
    const timeEnd = ref(store.state.calloffCreation.data.date_end_time)


    async function goToStepOne() {
        await store.dispatch('calloffCreation/fetchCartData', route.params.id)
        await store.dispatch('calloffCreation/fetchContractItemData', route.params.id)
    
        emit('changeStep', 1)
    }


    async function createCalloff() {
        if (!cartId.value) {
            goToStepOne()

        } else {
            try {
                store.commit('calloffCreation/setIsRequestingCartDataFlag', true)

                await makeRequest('post', 'api_call_off_cart_create_calloff', {'call_off_cart_item_id': cartId.value})

                Swal.fire({
                    icon: 'success',
                    title: 'on_site.calloff_creation.success.title'.trans(),
                    text: 'on_site.calloff_creation.success.text'.trans(),
                    confirmButtonText: 'on_site.calloff_creation.success.button_text'.trans()

                }).then(result => {
                    if (result.isConfirmed || result.isDismissed) {
                        store.commit('calloffCreation/setIsRequestingCartDataFlag', true)
                        router.push({ name: 'construction-site' })
                    }
                })

                store.commit('calloffCreation/setIsRequestingCartDataFlag', false)

            } catch (err) {
                store.commit('calloffCreation/setIsRequestingCartDataFlag', false)
                throw err
            }
        }
    }
</script>

<style lang="sass" scoped>
.step-card
    margin-bottom: 17px
    border-radius: 5px
    background-color: $white
    padding: 3px 11px 11px 11px
    text-align: left

    .calloff-data
        margin-top: 5px
        margin-bottom: 5px

        .calloff-data-table
            .delivery
                .data
                    padding-top: 5px
                    font-size: 14px

                    img
                        margin-right: 10px
                        margin-bottom: -3px
                        width: 20px
                    
                .clock
                    img
                        height: 17px

            .data-name
                padding-right: 7px
                font-family: PoppinsSemiBold
                font-size: 12px
                letter-spacing: 0.02px
                vertical-align: bottom
                color: $text-blue
                line-height: 26px

                &.note
                    vertical-align: baseline

            .data
                font-family: PoppinsRegular
                font-size: 12px
                letter-spacing: 0.02px
                color: $text-black
                line-height: 26px

    .button-container
        margin-bottom: 20px
        
        .change-calloff-button, .confirm-button
            width: 100%
            padding-top: 13px
            padding-bottom: 13px
            border: none
            border-radius: 3px
            font-family: PoppinsMedium
            font-size: 16px
            color: $white

        .change-calloff-button
            margin-bottom: 3px
            background-color: $gray-blue

        .confirm-button
            background-color: $green

.dark-mode
    .step-card
        background-color: $text-black

        .calloff-data
            .calloff-data-table
                .data-name
                    color: $dark-text-color

                .data
                    color: $white
</style>

<style lang="sass">
.step-card
    .calloff-data-table
        .data
            .moon-icon-container
                svg
                    height: 23px
                    width: auto
                    vertical-align: middle
                    fill: $white
                    stroke: $button-blue

                &.active
                    svg
                        fill: $button-blue
                        stroke: none

.dark-mode
    .calloff-data-table
        .data
            .moon-icon-container
                svg
                    stroke: $text-black
</style>