import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/User/Login.vue'
import Register from '../views/User/Register.vue'
import PasswordRecovery from '../views/User/PasswordRecovery.vue'
import TermsOfUse from '../views/User/TermsOfUse.vue'
import PrivacyTerms from '../views/User/PrivacyTerms.vue'
import Profile from '../views/User/Profile.vue'
import Messages from '../views/User/Messages.vue'
import MessageSingle from '../views/User/MessageSingle.vue'
import Settings from '../views/User/Settings.vue'
import Help from '../views/Info/Help.vue'
import QRScanner from '../views/QRScanner.vue'
import ContractOverview from '../views/ConstructionSite/ContractOverview.vue'
import ContractDetailOverview from '../views/ConstructionSite/ContractDetailOverview.vue'
import Dashboard from '../views/Dashboard.vue'
import Maintenance from '../views/ErrorPage/Maintenance.vue'
import DeliveryIncoming from '../views/Delivery/DeliveryIncoming.vue'
import DeliveryConfirmed from '../views/Delivery/DeliveryConfirmed.vue'
import DeliverySingle from '../views/Delivery/DeliverySingle.vue'
import DailyAmount from '../views/Amounts/DailyAmount.vue'
import TotalAmount from '../views/Amounts/TotalAmount.vue'
import ContractItems from '../views/CallOffs/ContractItems.vue'
import CallOffCreation from '../views/CallOffs/CallOffCreation.vue'

import store from '@/store'

const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		beforeEnter: () => {
			store.commit('announcement/setDoneRequesting', false)
			
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				return { name: 'dashboard' }
			}
		}
	},
	{
		path: '/register',
		name: 'register',
		component: Register
	},
	{
		path: '/password-recovery',
		name: 'password-recovery',
		component: PasswordRecovery
	},
	{
		path: '/terms-of-use',
		name: 'terms-of-use',
		component: TermsOfUse
	},
	{
		path: '/privacy-terms',
		name: 'privacy-terms',
		component: PrivacyTerms
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		beforeEnter: () => {
			store.commit('announcement/setDoneRequesting', false)
			
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				store.dispatch('contracts/fetchRecentContracts')
				store.dispatch('user/fetchButtonPermissions')
				store.dispatch('user/fetchDashboardButtons')
				store.commit('setLastAccessedRoute', '/dashboard')
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/messages',
		name: 'messages',
		component: Messages,
		beforeEnter: (to, from, next) => {
			const { fullPath } = from

			// 'Dashboard is default route'
			let lastAccessRoute = '/dashboard'
			if(fullPath && !fullPath.includes('messages')) {
				// Exclude single route
				lastAccessRoute = fullPath
			}

			// Update store
			store.commit('setLastAccessedRoute', lastAccessRoute)

			const isAuthenticated = store.state.userLoggedIn
			if(isAuthenticated) {
				// Dispatch action for fetching notifications
				store.dispatch('messages/fetchNotificationsOnLoad')

				// Continue to view
				next()
			} else {
				// Update store => we will try to redirect here after login
				store.commit('setLastAccessedRoute', to.fullPath)

				// Redirect to login
				next('/login')
			}
		}
	},
	{
		path: '/messages/single/:id',
		name: 'message-single',
		component: MessageSingle,
		beforeEnter: (to, from, next) => {
			const { fullPath } = from

			// 'Dashboard is default route'
			let lastAccessRoute = '/dashboard'
			if(fullPath && !fullPath.includes('messages')) {
				// Exclude single route
				lastAccessRoute = fullPath
			}

			// Update store
			store.commit('setLastAccessedRoute', lastAccessRoute)

			const isAuthenticated = store.state.userLoggedIn
			if(isAuthenticated) {
				// Dispatch fetch action
				const id = to.params.id
				store.dispatch('messages/fetchSingleNotification', {
				id
				})

				// Continue to view
				next()
			} else {
				// Update store => we will try to redirect here after login
				store.commit('setLastAccessedRoute', to.fullPath)

				// Redirect to login
				next('/login')
			}
		}
	},
	{
		path: '/profile',
		name: 'profile',
		component: Profile,
		beforeEnter: (to, from, next) => {
			const isAuthenticated = store.state.userLoggedIn
			if(isAuthenticated) {
				const selectedCompanyNumber = store.state.user.selectedCompany
					? store.state.user.selectedCompany.company_nr
					: null

				if (selectedCompanyNumber) {
					// Set fresh settings data to store
					store.dispatch('settings/fetchUserSettings', {
						company_number: selectedCompanyNumber
					})
				} else {
					console.error("User doesn't have a selected company!")
				}

				// Continue to view
				next()
			} else {
				// Update store => we will try to redirect here after login
				store.commit('setLastAccessedRoute', to.fullPath)

				// Redirect to login
				next('/login')
			}
		}
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		beforeEnter: (to, from, next) => {
			const isAuthenticated = store.state.userLoggedIn
			if(isAuthenticated) {
				const selectedCompanyNumber = store.state.user.selectedCompany
					? store.state.user.selectedCompany.company_nr
					: null

				if (selectedCompanyNumber) {
					// Set fresh settings data to store
					store.dispatch('settings/fetchUserSettings', {
						company_number: selectedCompanyNumber
					})
				} else {
					console.error("User doesn't have a selected company!")
				}

				// Continue to view
				next()
			} else {
				// Update store => we will try to redirect here after login
				store.commit('setLastAccessedRoute', to.fullPath)

				// Redirect to login
				next('/login')
			}
		}
	},
	{
		path: '/help',
		name: 'help',
		component: Help,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (!isAuthenticated) {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/construction-site',
		name: 'construction-site',
		component: ContractOverview,
		beforeEnter: (to, from, next) => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				// Dispatch action for fetching contract items
				store.dispatch('contracts/fetchContractsOnLoad')

				// Continue to view
				next()
			} else {
				// Update store => we will try to redirect here after login
				store.commit('setLastAccessedRoute', to.fullPath)

				// Redirect to login
				next('/login')
			}
		}
	},
	{
		path: '/contract-detail/:id',
		name: 'contract-detail',
		component: ContractDetailOverview,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				// Dispatch action for fetching recent delivery items
				store.dispatch('deliveryItems/fetchRecentDeliveries', {
					contract_number: store.state.contracts.selectedContract.contract_number
				})

				// Dispatch action for fetching recent daily amount items
				store.dispatch('amountItems/fetchRecentDailyAmounts', {
					contract_number: store.state.contracts.selectedContract.contract_number
				})
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/delivery-incoming/:id',
		name: 'delivery-incoming',
		component: DeliveryIncoming,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				// Dispatch action for fetching incoming delivery items
				store.dispatch('deliveryItems/fetchIncomingDeliveries')
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/delivery-confirmed/:id',
		name: 'delivery-confirmed',
		component: DeliveryConfirmed,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				// Dispatch action for fetching confirmed delivery items
				store.dispatch('deliveryItems/fetchConfirmedDeliveries')
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/delivery-single/:id',
		name: 'delivery-single',
		component: DeliverySingle,
		beforeEnter: route => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				store.dispatch('deliveryItems/fetchDeliverySingle', route.params.id)
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/daily-amount/:id',
		name: 'daily-amount',
		component: DailyAmount,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				// Dispatch action for fetching daily amount items
				store.dispatch('amountItems/fetchDailyAmountsOnLoad', {
					contract_number: store.state.contracts.selectedContract.contract_number
				})
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/total-amount/:id',
		name: 'total-amount',
		component: TotalAmount,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				// Dispatch action for fetching total amount items
				store.dispatch('amountItems/fetchTotalAmountsOnLoad', {
					contract_number: store.state.contracts.selectedContract.contract_number
				})
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/contract-items/:id',
		name: 'contract-items',
		component: ContractItems,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				// Dispatch action for fetching total amount items
				store.dispatch('contractItems/fetchContractItemsOnLoad', {
					contract_number: store.state.contracts.selectedContract.contract_number
				})
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/calloff-creation/:id',
		name: 'calloff-creation',
		component: CallOffCreation,
		beforeEnter: route => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				store.dispatch('calloffCreation/fetchCartData', route.params.id)
				store.dispatch('calloffCreation/fetchContractItemData', route.params.id)
                store.dispatch('configuration/fetchConfigurationData', route.params.id)
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/qr-scanner',
		name: 'qr-scanner',
		component: QRScanner,
		beforeEnter: () => {
			const isAuthenticated = store.state.userLoggedIn
			if (isAuthenticated) {
				if (!store.state.user.buttonPermissions.includes('qr-code-scanner')) {
					return { name: 'dashboard' }
				}
			} else {
				return { name: 'login' }
			}
		}
	},
	{
		path: '/maintenance',
		name: 'maintenance',
		component: Maintenance
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		component: Dashboard
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router