import store from '@/store/index'
import router from '@/router/index'
import { showErrorPopup } from './globalSwalPopups'
import OneSignalHelper from './OneSignalHelper'

/**
 * Makes post/get requests and handles maintenance and authorization.
 *
 * @param {string} method - Axios method.
 * @param {string} routeName - Name of the PHP route.
 * @param {object} params - Parameters to send. Optional. Default = {}
 * @param {boolean} authorized - Checks if authorization is necessary. Optional. Default = true
 * @return {Promise<object>|Promise<boolean>} - Response data.
 */
export async function makeRequest(method, routeName, params = {}, authorized = true) {
    let url = ''
    const payload = new FormData()
    let headers = {}

    // generate the PHP route by its name
    if (method == 'post') {
        url = Routing.generate(routeName, {}, true)
    } else if (method == 'get') {
        url = Routing.generate(routeName, params, true)

        // For routes where filters are used. Filters are added as a string that looks like &status[]=1&factory=2& ...
        // so when the url is generated, it includes &=%26 or &=&, so we remove it
        if (url.includes('filterString=%26')) {
            url = url.replace('filterString=%26', '')
        }
        if (url.includes('filterString=&')) {
            url = url.replace('filterString=&', '')
        }
        if (url.includes('&filterString=')) {
            url = url.replace('&filterString=', '')
        }

        url = decodeURIComponent(url)
    }
    // fill payload if the method is post
    if (method == 'post') {
        Object.keys(params).forEach(param => payload.append(param, params[param]))
    }

    // add authorization header if necessary
    if (authorized) {
        headers = {
            ...headers,
            'Authorization': `Bearer ${store.state.accessToken}`
        }
    }

    const { shortLocale } = window.appConfig
    if(shortLocale) {
        headers = {
            ...headers,
            'Accept-Language': shortLocale
        }
    }

    try {
        const response = await axios({
            method,
            url,
            data: method == 'post' ? payload : null,
            headers
        })
        const { data } = response

        return data

    } catch(error) {
        if (error.response && error.response.status == 503) {
            /**
             * 503 Service Unavailable
            */

            // Handle onesignal unsubscribe
            OneSignalHelper.unSubscribeUser()

            // Clear store values
            store.commit('userLoggedOut')
            store.commit('setAccessToken', '')
            store.commit('user/setUserData', null)
            store.commit('user/setSelectedCompany', null)
            store.commit('setLastAccessedRoute', '/dashboard')

            // Redirect to maintenance
            router.push({ name: 'maintenance' })

        } else if (error.response && error.response.status == 401) {
            /**
             * 401 Unauthorized
            */

            // Handle onesignal unsubscribe
            OneSignalHelper.unSubscribeUser()

            // Store actions
            store.commit('userLoggedOut')
            store.commit('setAccessToken', '')
            store.commit('user/setUserData', null)
            store.commit('user/setSelectedCompany', null)
            store.commit('setLastAccessedRoute', '/dashboard')

            // Redirect to login
            router.push({ name: 'login' })

        } else if (error.response && error.response.status == 422) {
            /**
             * Form errors
             */

            // Throw errors provided by backend so we can use them im actions/views
            throw error
        } else {
            showErrorPopup()
        }

        // Throw error so "other" await can catch it
        throw error
    }
}

/**
 * Makes post/get requests and handles maintenance and authorization.
 * [This takes function doesn't generate route => like this you can have params in route name along with formData]
 *
 * @param {string} method - Axios method.
 * @param {string} routeName - Generated route
 * @param {object} params - Parameters to send. Optional. Default = {}
 * @param {boolean} authorized - Checks if authorization is necessary. Optional. Default = true
 * @return {Promise<object>|Promise<boolean>} - Response data.
 */
 export async function makeRequestWithGeneratedRoute(method, routeName, params = {}, authorized = true) {
    let url = routeName
    const payload = new FormData()
    let headers = {}

    // fill payload if the method is post
    if (method == 'post') {
        Object.keys(params).forEach(param => payload.append(param, params[param]))
    }

    // add authorization header if necessary
    if (authorized) {
        headers = {
            ...headers,
            'Authorization': `Bearer ${store.state.accessToken}`
        }
    }

    const { shortLocale } = window.appConfig
    if(shortLocale) {
        headers = {
            ...headers,
            'Accept-Language': shortLocale
        }
    }

    try {
        const response = await axios({
            method,
            url,
            data: method == 'post' ? payload : null,
            headers
        })
        const { data } = response

        return data

    } catch(error) {
        if (error.response && error.response.status == 503) {
            /**
             * 503 Service Unavailable
            */

            // Handle onesignal unsubscribe
            OneSignalHelper.unSubscribeUser()

            // Clear store values
            store.commit('userLoggedOut')
            store.commit('setAccessToken', '')
            store.commit('user/setUserData', null)
            store.commit('user/setSelectedCompany', null)
            store.commit('setLastAccessedRoute', '/dashboard')

            // Redirect to maintenance
            router.push({ name: 'maintenance' })

        } else if (error.response && error.response.status == 401) {
            /**
             * 401 Unauthorized
            */

            // Handle onesignal unsubscribe
            OneSignalHelper.unSubscribeUser()

            // Store actions
            store.commit('userLoggedOut')
            store.commit('setAccessToken', '')
            store.commit('user/setUserData', null)
            store.commit('user/setSelectedCompany', null)
            store.commit('setLastAccessedRoute', '/dashboard')

            // Redirect to login
            router.push({ name: 'login' })

        } else {
            showErrorPopup()
        }

        // Throw error so "other" await can catch it
        throw error
    }
}
