<template>
	<div class="app-container" 
		:class="{ 'dark-mode': darkModeActivated, 
					'ios': isPlatformIOS, 
					'ios-old': isPlatformIOS && (iOSMajorVersion() < 15), 
					'standalone': isDisplayModeStandalone 
				}"
	>
		<router-view v-if="translationsExist" />
	</div>
</template>

<script setup>
	import { ref, computed, onBeforeMount, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'

	import Translations from '@/utils/Translations'
	import OneSignalHelper from '@/utils/OneSignalHelper'
	import NetworkHelper from '@/utils/NetworkHelper'
	import PullToRefresh from 'pulltorefreshjs'
	import Swal from 'sweetalert2'
	import { loader } from '@/utils/svgIcons'

	const store = useStore()

	const translationsExist = ref(false)
	const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)
	
	const isPlatformIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
	const isDisplayModeStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone
	
	const iOSMajorVersion = () => {
		if (isPlatformIOS) {
			const index = navigator.userAgent.indexOf( 'OS ' )
			let version = 0

			if (index >= 0) version = navigator.userAgent.substr(index + 3, 3).split('_')[0]

			return parseInt(version)

		} else {
			return false
		}
	}

	setTranslations()
	NetworkHelper.listenForChange()
	OneSignalHelper.notificationPermissionsChangedListener()

	async function setTranslations() {
		try {
			// Init application translations
			await Translations.init()

			// Change ref value, render app
			translationsExist.value = true

		} catch (err) {
			throw err
		}
	}

	onBeforeMount(() => {
		// Catch and save the event for installing the app later after login
		window.addEventListener('beforeinstallprompt', e => {

			// prevents the default popup opening on older browsers
			e.preventDefault()

			store.commit('user/setInstallPromptEvent', e)
		})
	})

	onMounted(() => {
		// Logic for checking system theme setting and changing the app theme
		let currentTheme = '10'
		if(store.state.user.data) {
			currentTheme = store.state.user.data.theme
		} else {
			currentTheme = store.state.settings.data.Theme
		}
		
		const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)
		const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

		if(currentTheme == 10) {
			if(!(darkModeActivated.value == isDarkMode)) {
				store.commit('settings/setDarkMode', isDarkMode)
			}
		} else if (currentTheme == 20) {
			store.commit('settings/setDarkMode', false)
		} else if(currentTheme == 30) {
			store.commit('settings/setDarkMode', true)
		}


		// Add the pull-to-refresh functionality on iOS standalone mode and on iOS version < 15
		if (isPlatformIOS) {
			if (isDisplayModeStandalone || iOSMajorVersion() < 15) {
				PullToRefresh.init({
					mainElement: 'body',
					instructionsPullToRefresh: ' ',
					instructionsReleaseToRefresh: ' ',
					instructionsRefreshing: ' ',
					iconArrow: ' ',
					iconRefreshing: loader,
					// if the classes 'no-scroll' or 'no-pull-to-refresh' are active, disable the logic
					shouldPullToRefresh() {
						return !window.scrollY && !$('html').hasClass('no-scroll') && !$('html').hasClass('no-pull-to-refresh')
					},
					onRefresh() {
						window.location.reload()
					}
				})
			}
		}
	})

	onUnmounted(() => {
		PullToRefresh.destroyAll()
	})
</script>