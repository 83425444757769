<template>
    <div class="overview-filters-page" :class="{'inside-subheader': subFilter}">
        <button class="filters-button-open open-filters-popup" @click="toggleFiltersPopup" :disabled="filtersDisabled">
            <img alt="..." src="@/assets/icons/filter.svg"/>
        </button>

        <div class="overview-filters-container hidden">
            <div class="filters-container" v-if="!subFilter">
                <SearchFilter
                    :disableSelect="'true'"
                />
                <button class="filters-button close-filters-popup" @click="toggleFiltersPopup">
                    <img alt="..." src="@/assets/icons/filter.svg"/>
                </button>
            </div>

            <div class="filters-main-content" v-show="showFilters">
                <button class="filter-button delete-filters-button"
                    @click="resetFilters"
                    disabled
                >{{ "on_site.filters.delete_all_filters".trans() }}</button>
                
                <Loader v-show="isRequesting" />
                <div class="filter-tags-container" v-show="!isRequesting">
                    <div class="filter-tags" v-for='(value, name) in items' :key="`item-${name}`">
                        <p class="filter-name">{{ `on_site.filter_category.${name}`.trans() }}</p>
                        <div class="tags-container">
                            <div class="filter-tag" :class="name"
                                v-for='(filterTag, index) in value' 
                                :key="`item-${index}`"
                                @click="sortFilters($event)"
                                :data-filter-id="index"
                                :data-filter-type="name"
                            >{{ filterTag }}</div>
                        </div>
                    </div>
                </div>

                <div class="filter-datepicker-container" v-if="showDatepicker">
                    <p class="filter-name">{{ 'on_site.filter_category.datepicker'.trans() }}</p>
                    <flat-pickr v-model="dateFilter"
                        placeholder="Select date"
                        :config="config"
                        @onChange="setFilterDate"
                    ></flat-pickr>
                </div>

                <button class="filter-button apply-filters-button"
                    @click="setFilters"
                    disabled
                >{{ "on_site.filters.apply_filters".trans() }}</button>
            </div>
            <div class="filters-main-content-empty" v-show="!showFilters && selectedFilters == '' && selectedQuery == ''">
                {{'on_site.filter_page.empty'.trans()}}
            </div>
            <div class="filters-main-content-empty" v-show="!showFilters && selectedFilters != '' && selectedQuery != ''">
                {{'on_site.filter_page.empty_because_of_search_term'.trans()}}
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted, onUpdated, watch } from 'vue'
    import { useStore } from 'vuex'
    import { ref } from 'vue'
    import Header from '@/components/Common/Header.vue'
    import SubHeader from '@/components/SubHeader/SubHeader.vue'
    import SearchFilter from '@/components/Common/SearchFilter.vue'
    import Loader from '@/components/Common/Loader.vue'
    import Footer from '@/components/Common/Footer.vue'
	import { updateUrlParameterMulti } from '@/utils/updateFilterUrl.js'
    import { updateUrlParameter } from '@/utils/updateFilterUrlSingle.js'
    import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import { German } from 'flatpickr/dist/l10n/de'

    const props = defineProps({
        filterAction: String,
        filtersRoute: String,
        subFilter: {
            type: Boolean,
            default: false
        },
        contractItems: {
            type: Boolean,
            default: false
        },
        parameterName: String,
        parameterValue: String,
        pageName: String,
        showDatepicker: {
            type: Boolean,
            default: false
        },
        filtersDisabled: {
            type: Boolean,
            default: false
        }
    })

    const filterAction = ref(props.filterAction)
    const filtersRoute = ref(props.filtersRoute)
    const subFilter = ref(props.subFilter)
    const contractItems = ref(props.contractItems)
    const parameterName = ref(props.parameterName)
    const parameterValue = ref(props.parameterValue)
    const pageName = ref(props.pageName)

    const store = useStore()
    const emit = defineEmits(['filtersParameter'])

    onMounted(() => {
        if(subFilter.value || contractItems.value) {
            store.dispatch(filtersRoute.value, {
                [parameterName.value]: parameterValue.value,
            })
        } else {
            store.dispatch(filtersRoute.value)
        }
    })

    // Variable for creating the all filters string
    let allFilters = ''

    // Datepicker configuration
    const dateFilter = ref('')

    const config = {
        inline: true,
        dateFormat: "d-m-Y",
        locale: German,
        monthSelectorType: "static",
        onDayCreate: function(dObj, dStr, fp, dayElem){
            // Logic for adding a class to weekend days
            const dayOfWeek = dayElem.dateObj.getDay()
            const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)

            if(isWeekend) {
                dayElem.classList.add('weekendDay')
            }
        }
    }

    // Logic for updating all filters with date
    let selectedDate = ''
    function setFilterDate() {
        const dateFilterValue = dateFilter.value

        selectedDate = dateFilterValue
        
        allFilters = updateUrlParameter(allFilters, 'date', dateFilterValue)

        if(selectedQuery.value != '') {
            store.dispatch(filtersRoute.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': allFilters,
                query: selectedQuery.value
            })
        } else {
            store.dispatch(filtersRoute.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': allFilters
            })
        }
        
        if(allFilters == '') {
            $('.delete-filters-button').attr('disabled', true)
            $('.apply-filters-button').attr('disabled', true)
        } else {
            $('.delete-filters-button').attr('disabled', false)
            $('.apply-filters-button').attr('disabled', false)
        }
    }

    let items = ''
    if(pageName.value == 'daily') {
        items = computed(() => store.state.filters.dailyAmountFilterItems)
    } else if(pageName.value == 'total') {
        items = computed(() => store.state.filters.totalAmountFilterItems)
    } else if(pageName.value == 'confirmed') {
        items = computed(() => store.state.filters.confirmedDeliveryFilterItems)
    } else if(pageName.value == 'incoming') {
        items = computed(() => store.state.filters.incomingDeliveryFilterItems)
    } else if(pageName.value == 'contractItems') {
        items = computed(() => store.state.filters.contractItemsFilterItems)
    } else {
        items = computed(() => store.state.filters.contractFilterItems)
    }

    let showFilters = ''
    if (pageName.value == 'daily') {
        showFilters = true
    } else {
        // show filters if there is at least one non-empty category
        showFilters = computed(() => Object.values(items.value).reduce((x,y) => Object.values(x).length + Object.values(y).length, 0) != 0)
    }

    const isRequesting = computed(() => store.state.filters.isFiltersRequesting)
    const selectedQuery = computed(() => store.state.filters.selectedQuery)
    const selectedFilters = computed(() => store.state.filters.selectedFilters)
    const selectedCalendarDate = computed(() => store.state.filters.selectedDate)

    // Logic for opening/closing filters popup and hidding contracts when filters popup is open
	function toggleFiltersPopup() {
		$('.overview-filters-container').toggleClass('hidden')
		$('.filter-parent-container').toggleClass('hidden')
        $('.filters-button-open').toggleClass('open')

        let filterIsOpen = $('.filters-button-open').hasClass('open')

		// Toggle scroll and/or pull to refresh logic on body
		if (filterIsOpen) {
            if (props.subFilter) {
                // this class only disables pull-to-refresh but keeps the scroll behavior
                $('html').addClass('no-scroll')

            } else {
                // this class disables scrolling which also disables pull-to-refresh
                $('html').addClass('no-pull-to-refresh')
            }
        } else {
            if (props.subFilter) {
                $('html').removeClass('no-scroll')
            }
            else {
                $('html').removeClass('no-pull-to-refresh')
            }
        }
	}

    // Logic for sorting filters when one is clicked
    function sortFilters(event) {
        event.target.classList.toggle('active')
        const filterKey = event.target.dataset.filterType
        const filterValue = event.target.dataset.filterId

        if(filterKey == 'status') {
            allFilters = updateUrlParameter(allFilters, filterKey, filterValue)
        } else {
            allFilters = updateUrlParameterMulti(allFilters, filterKey, filterValue)
        }

        if(selectedQuery.value != '') {
            store.dispatch(filtersRoute.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': allFilters,
                query: selectedQuery.value
            })
        } else {
            store.dispatch(filtersRoute.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': allFilters
            })
        }
        
        // Check if any or no filters were set to enable reseting when all
        // filter tags are deactivated but some filters are still active
        if(allFilters == '' && !$('.filters-button-open').hasClass('active')) {
            $('.delete-filters-button').attr('disabled', true)
            $('.apply-filters-button').attr('disabled', true)
        } else {
            $('.delete-filters-button').attr('disabled', false)
            $('.apply-filters-button').attr('disabled', false)
        }
    }

    onUpdated(() => {
        // In case when one filter tag sorts out another which is active
        // Adding active classes to filter tags so all active tags are shown
        if(isRequesting.value == false) {
            $('.filter-tag').each(function(index, element) {
                if(allFilters.includes(`${element.dataset.filterType}[]=${element.dataset.filterId}`) || allFilters.includes(`${element.dataset.filterType}=${element.dataset.filterId}`)) {
                    element.classList.add('active')
                } else {
                    element.classList.remove('active')
                }
            })
        }
    })

    function setFilters(event) {
        if(selectedQuery.value != '') {
            store.dispatch(filterAction.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': allFilters,
                query: selectedQuery.value
            })
        } else {
            store.dispatch(filterAction.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': allFilters
            })
        }

        emit('filtersParameter', allFilters)

        // Add green color to filter toggler when filters are active
        if(allFilters != '') {
			$('.filters-button-open').addClass('active')
		} else {
			$('.filters-button-open').removeClass('active')
		}

        // Close filter popup
        $('.overview-filters-container').addClass('hidden')
        $('.contracts-container').removeClass('hidden')
        $('.filters-button-open').removeClass('open')

        // Check if any or no filters were set to enable reseting when all
        // filter tags are deactivated but some filters are still active
        if(allFilters == '' && !$('.filters-button-open').hasClass('active')) {
            $('.delete-filters-button').attr('disabled', true)
            $('.apply-filters-button').attr('disabled', true)
        } else {
            $('.delete-filters-button').attr('disabled', false)
            $('.apply-filters-button').attr('disabled', false)
        }

        store.commit('filters/setSelectedFilters', allFilters)
        store.commit('filters/setSelectedDate', selectedDate)

        $('.filter-parent-container').removeClass('hidden')

        $('html').removeClass('no-scroll')
        $('html').removeClass('no-pull-to-refresh')
    }

    function resetFilters() {
        store.dispatch(filtersRoute.value, {
            [parameterName.value]: parameterValue.value,
        })

        if(selectedQuery.value != '') {
            store.dispatch(filterAction.value, {
                [parameterName.value]: parameterValue.value,
                query: selectedQuery.value
            })
        } else {
            store.dispatch(filterAction.value, {
                [parameterName.value]: parameterValue.value,
            })
        }

        // Reset filters popup - disable buttons, deactivate filter tags
        $('.delete-filters-button').attr('disabled', true)
        $('.apply-filters-button').attr('disabled', true)
        $('.filter-tag').removeClass('active')

        // Close filter popup and show item container
        $('.overview-filters-container').addClass('hidden')
        $('.contracts-container').removeClass('hidden')

        allFilters = ''
        emit('filtersParameter', '')

        dateFilter.value = ''

        store.commit('filters/setSelectedFilters', '')
        store.commit('filters/setSelectedDate', '')

        // Remove green color from filter popup toggler
        $('.filters-button-open').removeClass('active')
        $('.filters-button-open').removeClass('open')

        $('.filter-parent-container').removeClass('hidden')

        $('html').removeClass('no-scroll')
        $('html').removeClass('no-pull-to-refresh')
    }
</script>

<style lang="sass" scoped>
.overview-filters-page
    // fix for safari scroll
    &:not(.inside-subheader)
        .overview-filters-container
            position: absolute
        
    .filters-button-open
        display: flex
        align-items: center
        border: none
        border-radius: 4px
        width: 39px
        height: 39px
        padding: 0
        background-color: $button-blue

        img
            margin: 0 auto

        &.active
            background-color: $green

        &.open
            background-color: $green

        &:disabled
            cursor: not-allowed

    .overview-filters-container
        top: -10px
        left: 0px
        padding-bottom: 60px
        z-index: 2
        width: 100%
        height: calc(100vh - 154px)
        overflow: auto
   
        background-color: $white //$background-gray
        box-shadow: 0 3px 6px #01000029
        transition: 1s

        .filters-container
            display: flex
            justify-content: space-between
            margin: 22px auto 0px auto
            width: 94%

            .filters-button
                display: flex
                align-items: center
                border: none
                border-radius: 4px
                padding: 0px
                width: 39px
                height: 39px
                background-color: $green

        .filters-main-content-empty
            margin-top: 30px
            margin-left: 24px
            text-align: left

        .filter-button
            border: none
            border-radius: 4px
            width: 94%
            height: 50px
            font-family: PoppinsMedium
            font-size: 16px
            line-height: 25px
            color: $white
            box-shadow: 0 1px 3px #01000029

            &.delete-filters-button
                margin-top: 10px
                background-color: $red

                &:disabled
                    background-color: $gray-blue
                    cursor: not-allowed

            &.apply-filters-button
                margin-top: 10px
                background-color: $green

                &:disabled
                    background-color: $gray-blue
                    cursor: not-allowed

        .filters-title
            margin: 0px
            padding-top: 12px
            padding-left: 24px
            font-family: PoppinsMedium
            font-size: 12px
            letter-spacing: 0.02px
            line-height: 16px
            text-align: left
            color: $text-gray

        .filter-tags-container
            margin: 0 auto
            width: 94%
            text-align: left

            .filter-tags
                display: flex
                flex-direction: column
                margin-bottom: 15px

                .filter-name
                    margin-bottom: 5px
                    font-family: PoppinsBold
                    font-size: 12px
                    letter-spacing: 0.02px
                    line-height: 16px
                    color: $dark-blue

                .tags-container
                    display: flex
                    flex-wrap: wrap

                    .filter-tag
                        margin-right: 5px
                        margin-bottom: 5px
                        border: 1px solid $gray-blue
                        border-radius: 4px
                        height: 40px
                        padding: 0px 20px
                        background-color: $white

                        font-family: PoppinsRegular
                        font-size: 14px
                        letter-spacing: 0.03px
                        line-height: 40px
                        color: $text-lighter-gray
                        cursor: pointer

                        &:hover
                            background-color: $button-blue
                            color: $white
                        
                        &.active
                            background-color: $button-blue
                            color: $white

// fix for safari web mode
.app-container
    &:not(.standalone)
        &.ios
            .apply-filters-button
                margin-bottom: 20px

        &.ios-old
            .apply-filters-button
                margin-bottom: 60px

.dark-mode
    .overview-filters-page
        .overview-filters-container
            background-color: $dark-button-gray

            .filters-main-content-empty
                color: $dark-text-color
                
            .filter-tags-container
                .filter-tags
                    .filter-name
                        color: $dark-text-color

                    .filter-tag
                        background-color: $text-black
                        color: $dark-text-color

    .select2-container--default.select2-container--disabled .select2-selection--single
        background-color: $text-black !important
        color: $dark-text-color !important
</style>

<style lang="sass">
.dark-mode
    .overview-filters-page
        .select2-container--default.select2-container--disabled .select2-selection--single
            background-color: $text-black !important
            color: $dark-text-color !important

    .filter-datepicker-container
        .filter-name
            color: $dark-text-color

        .flatpickr-calendar
            background-color: $dark-button-gray

            .flatpickr-months
                .flatpickr-prev-month,
                .flatpickr-next-month
                    svg
                        fill: $dark-text-color 

                .flatpickr-current-month 
                    color: $dark-text-color

            .flatpickr-weekdays
                .flatpickr-weekday
                    color: $white

            .flatpickr-days
                .dayContainer
                    .flatpickr-day
                        color: $dark-text-color

                        &.selected
                            background-color: $button-blue

                        &.weekendDay
                            background-color: $flatpicker-dark

                            &.selected
                                background-color: $button-blue

                        &:hover
                            border: $button-blue
                            background-color: $button-blue

            .flatpickr-weekdaycontainer span:nth-child(7n+6),
            .flatpickr-weekdaycontainer span:nth-child(7n)
                background-color: $flatpicker-dark

.filter-datepicker-container
    margin: 0 auto
    width: 94%

    .flatpickr-input
        display: none
    
    .filter-name
        margin-bottom: 5px
        font-family: PoppinsBold
        font-size: 12px
        letter-spacing: 0.02px
        line-height: 16px
        color: $dark-blue
        text-align: left

    .flatpickr-calendar
        width: 100%
        max-width: 355px
        margin: 0 auto
        box-shadow: none

    .flatpickr-months
        .flatpickr-current-month 
            font-family: PoppinsMedium
            font-size: 18px
            line-height: 27px
            color: $dark-blue

        .flatpickr-next-month
            right: 5px

    .flatpickr-rContainer
        margin: 0 auto

    .flatpickr-weekdays
        font-family: PoppinsLight
        font-size: 16px
        height: 50px
        color: $text-lighter-gray

        .flatpickr-weekdaycontainer
            height: 50px

            .flatpickr-weekday
                line-height: 50px

    .flatpickr-days
        min-width: 350px

        .dayContainer
            min-width: 350px

            .flatpickr-day
                min-width: 50px
                height: 50px
                font-family: PoppinsRegular
                font-size: 13px
                line-height: 49px
                color: $dark-blue

                &.selected
                    border: none
                    color: $white
                    background-color: $dark-blue

                &.weekendDay
                    border-radius: 0px
                    background-color: $datepicker-gray

                    &.selected
                        background-color: $dark-blue
            
            .prevMonthDay
                color: $text-lighter-gray

    .flatpickr-weekdaycontainer span:nth-child(7n+6),
    .flatpickr-weekdaycontainer span:nth-child(7n)
        background-color: $datepicker-gray

@media (max-width: 360px)
    .filter-datepicker-container
        .flatpickr-days
            min-width: 300px

            .dayContainer
                min-width: 300px

                .flatpickr-day
                    min-width: 43.82px
                    height: 43.82px
                    line-height: 47px
</style>