<template>
  <div class="search-filter">
        <Select2 class="select-filters-container"
            :placeholder="'on_site.search_filter.placeholder'.trans()"
            v-if="disableSelect"
            disabled
        />

        <div class="select-filter-box" v-else>
            <Select2 id="contract-overview-select" class="select-filters-container"
                :options="selectOptions" :settings="selectSettings"
                @select="selectEvent($event)"
                @click="startSelect()"
                v-click-outside="restartSelect"
            />
            <div class="select-filter-reset hidden" @click="resetSelect()">
                <img src="@/assets/icons/close-gray.svg" alt="clear"/>
            </div>
        </div>
  </div>
</template>

<script setup>
    import { computed, onMounted } from 'vue'
    import Select2 from '@/components/Select2'
    import { ref } from 'vue'
    import { showErrorPopup } from '@/utils/globalSwalPopups.js'
    import { useStore } from 'vuex'
    
    const emit = defineEmits(['queryParameter'])

    const store = useStore()

    const props = defineProps({
        searchAction: String,
        disableSelect: String,
        filtersRoute: {
            type: String,
            default: ''
        },
        parameterName: String,
        parameterValue: String,
    })

    const searchAction = ref(props.searchAction)
    const filtersRoute = ref(props.filtersRoute)
    const disableSelect = ref(props.disableSelect) 
    const selectedFilters = computed(() => store.state.filters.selectedFilters)
    const parameterName = ref(props.parameterName)
    const parameterValue = ref(props.parameterValue)
    
    const selectOptions = []
    const selectSettings = {
        placeholder: 'on_site.search_filter.placeholder'.trans(),
        containerCssClass: 'contract-items-select-container',
        dropdownCssClass: 'contract-items-select-dropdown',
        tags: true,
        createTag: function (tag) {
            return {id: tag.term, text: tag.term, tag: true};
        },
        multiple: true
    }
    

    let selectValue = ''
    function selectEvent(val) {
        selectValue = val.text

        const query = encodeURIComponent(val.id)

        if(selectedFilters.value != '') {
            store.dispatch(searchAction.value, {
                [parameterName.value]: parameterValue.value,
                query,
                'filterString': selectedFilters.value
            })
        } else {
            store.dispatch(searchAction.value, {
                [parameterName.value]: parameterValue.value,
                query
            })
        }

        if(val != '') {
            store.dispatch(filtersRoute.value, {
                [parameterName.value]: parameterValue.value,
                query,
                'filterString': selectedFilters.value
            })
        } else {
            store.dispatch(filtersRoute.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': selectedFilters.value
            })
        }

        emit('queryParameter', query)

        $('.select-filter-reset').removeClass('hidden')

        store.commit('filters/setSelectedQuery', query)
    }

    function resetSelect() {
        selectValue = ''
        $('#contract-overview-select').val(null).trigger('change')
        $('#contract-overview-select').empty().trigger('change')
        $('.select-filter-reset').addClass('hidden')

        if(selectedFilters.value != '') {
            store.dispatch(searchAction.value, {
                [parameterName.value]: parameterValue.value,
                'filterString': selectedFilters.value
            })
        } else {
            store.dispatch(searchAction.value, {
                [parameterName.value]: parameterValue.value,
            })
        }

        store.dispatch(filtersRoute.value, {
            [parameterName.value]: parameterValue.value,
            'filterString': selectedFilters.value
        })


        emit('queryParameter', '')

        store.commit('filters/setSelectedQuery', '')
        $('#contract-overview-select').attr('disabled', false)
    }

    // Logic for enabling input in search with another active query - remove query and focus input
    function startSelect() {
        if(selectValue != '') {
            $('#contract-overview-select').empty().trigger('change')
            $('#contract-overview-select').attr('disabled', false)
        }

        $('.select2-search__field').focus()
    }

    const restartSelect = () => {
        $('.select2-search__field').val(selectValue)
    }
</script>

<style lang="sass">
.select-filters-container
    .select2-container
        .contract-items-select-container
            .select2-search__field
                min-width: 100%
                margin-top: 0 !important
                padding-left: 3px !important
                font-family: PoppinsRegular !important
                font-size: 14px
                letter-spacing: 0.03px
                color: $text-lighter-gray

            .select2-selection__choice
                max-width: 100%
                text-overflow: ellipsis
                overflow: hidden

                ~.select2-search
                    position: absolute
                    opacity: 0
                    display: none

        &.select2-container--open,
        &.select2-container--focus
            .select2-selection--multiple
                border: 1px solid #aaa !important
                border-radius: 4px !important

        &.select2-container--disabled
            .select2-selection__placeholder
                font-size: 14px
                line-height: 1px
                letter-spacing: 0.03px

.select2-container--disabled
    .contract-items-select-container
        background-color: $white !important

        .select2-selection__choice
            margin-top: 2px
            border: none
            background-color: transparent

.contract-items-select-dropdown
    .select2-results__message
        display: none

.search-filter
    width: 96%
    margin-right: 3px

    .select-filter-box
        position: relative

        .select-filter-reset
            position: absolute
            top: 6px
            right: 10px
            cursor: pointer

    .select-filters-container
        text-align: left

        .select2-container
            width: 100% !important

            .select2-selection
                display: flex
                align-items: center
                height: 39px
                width: 100%

                background-image: url("@/assets/icons/search.svg")
                background-repeat: no-repeat
                background-position: 10px 10px

                .select2-selection__rendered
                    margin-left: 30px
                    width: 70%

                    .select2-selection__choice
                        margin-top: 0px
                        border: none
                        background-color: transparent

                    .select2-selection__choice__remove
                        display: none

                .select2-selection__arrow
                    display: none

.dark-mode
    .construction-site-container
        .contract-items-select-container
            background-color: $dark-button-gray
            border: 1px solid $component-border

            .select2-search__field
                color: $dark-text-color

    .select2-container--disabled 
        .contract-items-select-container
            background-color: $dark-button-gray !important
            color: $dark-text-color !important

.contract-items-select-dropdown
    .select2-results__options
        border-bottom-left-radius: 4px
        border-bottom-right-radius: 4px

        .select2-results__option
            padding-top: 10px
            padding-bottom: 10px
            padding-left: 12px
            font-family: PoppinsRegular
            font-size: 14px
            color: $placeholder-gray

            &.select2-results__option--highlighted
                background-color: $light-blue
                color: $white
</style>