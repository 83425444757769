<template>
	<div class="contract-items-page">
		<Header :pageTitle="pageTitle" />
		<SubHeader :currentPage="currentPage"/>

		<Loader v-show="isRequesting" />
		<div class="contract-items-container" v-show="!isRequesting">
			<div class="filters-container">
				<SearchFilter
					:searchAction="'contractItems/fetchContractItemsOnLoad'"
					:filtersRoute="'filters/fetchContractItemsFilterItems'"
					@queryParameter="getQueryParameter"
					:parameterName="'contract_number'"
					:parameterValue="store.state.contracts.selectedContract.contract_number"
				/>
				<OverviewFilters
					:key="store.state.contracts.selectedContract.contract_number"
					:filterAction="'contractItems/fetchContractItemsOnLoad'"
					:filtersRoute="'filters/fetchContractItemsFilterItems'"
					@filtersParameter="getFiltersParameter"
					:parameterName="'contract_number'"
					:parameterValue="store.state.contracts.selectedContract.contract_number"
					:contractItems="true"
					:pageName="'contractItems'"
				/>
			</div>
			<div class="contract-items">
				<p class="contract-items-title" v-if="hasResults">{{'on_site.contract-items.title'.trans()}}</p>
				<div class="contract-items-container filter-parent-container" v-if="hasResults">
					<ContractItemCard
						v-for="item in items"
						:key="item.contract_item_id"

						:contractItemId="item.contract_item_id"
						:materialName="item.material_designation"
						:materialNumber="item.material_number"
						:deliveredAmount="item.delivered_quantity_raw"
						:openAmount="item.remaining_quantity_raw"
						:factoryName="item.factory_name"
						:factoryNumber="item.factory_number"
						:quantityUnit="item.quantity_unit"
					/>
				</div>
				<div class="contract-items-title empty" v-else>
					<span class="empty-title">{{'on_site.contract-items.empty'.trans()}}</span>
						<div class="empty-screen-icon-container">
							<img class="contract-items" src="@/assets/icons/amount-empty.svg" alt="empty_contract-items"/>
						</div>
					<span class="empty-message">{{'on_site.contract-items.empty_message'.trans()}}</span>
				</div>
			</div>
		</div>

		<Footer/>
	</div>
</template>

<script setup>
	import Header from '@/components/Common/Header.vue'
	import SubHeader from '@/components/SubHeader/SubHeader.vue'
	import SearchFilter from '@/components/Common/SearchFilter.vue'
	import OverviewFilters from '@/components/Common/OverviewFilters.vue'
	import Footer from '@/components/Common/Footer.vue'
	import Loader from '@/components/Common/Loader.vue'
	import ContractItemCard from '@/components/CallOffs/ContractItemCard.vue'
	import { computed, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'

	const store = useStore()
	const route = useRoute()

	const pageTitle = computed(() => store.state.contracts.selectedContract.contract_name)
	const currentPage = 'on_site.menu.calloff_creation'.trans()

	const items = computed(() => store.state.contractItems.contractItems.items)

	// Pagination and has results flag
	const hasResults = computed(() => store.state.contractItems.contractItems && store.state.contractItems.contractItems.items && store.state.contractItems.contractItems.items.length > 0)
	const pagination = computed(() => store.state.contractItems.contractItems.pagination)

	// Loading flags
	const isRequesting = computed(() => store.state.contractItems.isContractItemsRequesting)
	const isRequestingPaginated = computed(() => store.state.contractItems.isContractItemsPaginationRequesting)

	onMounted(() => {
		initializeInfiniteScroll()
	})

	// Query parameter from search component that needs to be put into pagination for loading more results
	let paginationQueryParameter = ''
	function getQueryParameter(val) {
		paginationQueryParameter = val
	}

	// Filters parameter from filters popup - used for pagination
	let filtersParameter = ''
	function getFiltersParameter(val) {
		filtersParameter = val
	}

	// Pagination logic
	const initializeInfiniteScroll = () => {
		window.onscroll = () => {
			const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
			if (bottomOfWindow && !isRequesting.value && !isRequestingPaginated.value) {
				const { current_page, page_size, total_items, total_pages, has_next_page } = pagination.value
				if (has_next_page && !$('.contracts-container').hasClass('hidden')) {
					if(paginationQueryParameter != '') {
						store.dispatch('contractItems/fetchContractItemsPaginated', {
							contract_number: store.state.contracts.selectedContract.contract_number,
							page: current_page + 1,
							page_size: page_size,
							query: paginationQueryParameter,
							'filterString': filtersParameter
						})
					} else {
						store.dispatch('contractItems/fetchContractItemsPaginated', {
							contract_number: store.state.contracts.selectedContract.contract_number,
							'filterString': filtersParameter,
							page: current_page + 1,
							page_size: page_size,
						})
					}
				}
			}
		}
	}

	onUnmounted(() => {
        store.commit('filters/setSelectedFilters', '')
        store.commit('filters/setSelectedQuery', '')
    })
</script>

<style lang="sass" scoped>
.contract-items-page
	padding-top: 51px + 48px + 11px // header + subheader + margin from design
	padding-bottom: 81px // footer + some extra height
	min-height: calc(100vh - 51px - 48px - 81px)

	.contract-items-container
		.filters-container
			display: flex
			justify-content: space-between
			margin: 0px auto 5px auto
			width: 94%

	.contract-items
		width: 100%

		.filter-parent-container
			&.hidden
				display: none

	.contract-items-title
		margin-left: 24px
		font-family: PoppinsMedium
		font-size: 12px
		letter-spacing: 0.02px
		line-height: 16px
		color: $text-gray
		text-align: left

		&.empty
			margin-top: 15px
			padding-left: 0px
			margin-left: 0px
			text-align: center
			font-family: PoppinsMedium
			font-size: 12px
			color: $text-gray

			.empty-screen-icon-container
				margin-bottom: 10px

				img
					height: 200px

			.empty-title
				font-family: PoppinsBold
				font-size: 16px

	.contract-items-container
		display: flex
		justify-content: space-evenly
		flex-wrap: wrap
</style>

<style lang="sass">
.contract-items-page
	.contract-items-container
		.overview-filters-container
			margin-top: 98px
</style>