import { makeRequest } from '@/utils/makeRequest'

const state = {
    contractItems: {
        items: [],
        pagination: {}
    },
    isContractItemsRequesting: false,
    isContractItemsPaginationRequesting: false
}

const getters = {

}

const actions = {
    // First chunk of data
    // Dispatched in "beforeEnter" of router
    fetchContractItemsOnLoad: async (
        { commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        commit('setContractItemsRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_get_contract_items', params )
            const { data } = response

            // This will also reset items & pagination
            commit('setContractItemsData', data)
            commit('setContractItemsRequestingFlag', false)
        } catch(err) {
            commit('setContractItemsRequestingFlag', false)
            throw err
        }
    },
    // Paginated chunks
    // Dispatched from "onscroll" listener
    fetchContractItemsPaginated: async ({ commit, rootState }, 
        params = { 'contract_number': rootState.contracts.selectedContract.contract_number }
    ) => {
        commit('setContractItemsPaginationRequestingFlag', true)
        try {
            const response = await makeRequest('get', 'api_get_contract_items', params )
            const { data } = response

            // This will set merge items & update pagination
            commit('setContractItemsPaginatedData', data)
            commit('setContractItemsPaginationRequestingFlag', false)
        } catch(err) {
            commit('setContractItemsPaginationRequestingFlag', false)
            throw err
        }
    }
}

const mutations = {
    // Onload
    setContractItemsRequestingFlag: (state, value) => {
        state.isContractItemsRequesting = value
    },
    setContractItemsData: (state, contractItems) => {
        state.contractItems = contractItems
    },
    // Pagination
    setContractItemsPaginationRequestingFlag: (state, value) => {
        state.isContractItemsPaginationRequesting = value
    },
    setContractItemsPaginatedData: (state, contractItems) => {
        const { items, pagination } = contractItems
        state.contractItems = {
            pagination,
            items: [ ...state.contractItems.items, ...items ]
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
