<template>
    <div class="step-card">
        <div class="contact-person-search-container">
            <Select2 id="calloff-contact-person-search" class="select-filters-container"
                :options="contactPersonSelectOptions" :settings="selectSettings"
                @select="contactPersonSearchSelect"
                @open="focusInput"
            />
        </div>

        <div class="data-container">
            <div class="contact-person-card"
                v-for="person in contactPersons" :key="person.id"
            >
                <input type="radio" :id="person.id" name="contact_person" :value="person.id" v-model="selectedPerson">
                <label :for="person.id">
                    <div class="contact-person-container">
                        <div class="contact-person-data">
                            <p>
                                <img alt=" " src="../../assets/icons/user-icon.svg"/>
                                <span>{{person.first_name}} {{person.last_name}}</span>
                            </p>
                            <p>
                                <img alt=" " src="../../assets/icons/phone-icon.svg"/>
                                <span>{{person.phone_country}} {{person.phone_number}}</span>
                            </p>
                            <p>
                                <img alt=" " src="../../assets/icons/mobile-phone-icon.svg"/>
                                <span>{{person.mobile_country}} {{person.mobile_number}}</span>
                            </p>
                            <p>
                                <img alt=" " src="../../assets/icons/email-icon.svg"/>
                                <span>{{person.email}}</span>
                            </p>
                        </div>
                        <div class="checkbox-container">
                            <img class="inactive" alt=" " src="../../assets/icons/checkmark-gray.svg"/>
                            <img class="active" alt=" " src="../../assets/icons/checkmark.svg"/>
                        </div>
                    </div>
                    <div class="spacer"></div>
                </label>
            </div>
        </div>

        <div class="button-container">
            <button class="back-button" @click="changeStepAndSubmit(1)">
                {{'on_site.calloff_creation.previous_step'.trans()}}
            </button>
            <button class="next-button" :class="{'disabled': goToStepThreeButtonDisabled}" @click="changeStepAndSubmit(3)">
                {{'on_site.calloff_creation.next_step'.trans()}}
            </button>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watch, onUnmounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'
    import Select2 from '@/components/Select2'

    const store = useStore()
    const route = useRoute()
    const emit = defineEmits(['changeStep'])

    const isPlatformIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

    const cartId = ref(store.state.calloffCreation.data.id)
    const contactPersons = ref(store.state.calloffCreation.data.available_contact_persons)
    const selectedPerson = ref(store.state.calloffCreation.data.contact_person ? store.state.calloffCreation.data.contact_person.id : '')
    const goToStepThreeButtonDisabled = computed(() => !selectedPerson.value)

    const contactPersonSelectOptions = contactPersons.value.map(object => ({
        'id': object.id,
        'text': `${object.first_name} ${object.last_name}`
    }))

    let selectDropdownCssClass = 'contact-person-select-dropdown'
    if (isPlatformIOS) selectDropdownCssClass += ' ios-platform'
    if (darkModeActivated.value) selectDropdownCssClass += ' select2-dark-mode'

    const selectSettings = {
        minimumInputLength: 1,
        placeholder: 'on_site.contact_person.search_filter.placeholder'.trans(),
        containerCssClass: 'contact-person-select-container',
        dropdownCssClass: selectDropdownCssClass,
        //filterWithoutAjax: true,
        language: {
            inputTooShort: () => 'on_site.search.input_too_short'.trans(),
            noResults: () => 'on_site.search.no_results'.trans()
        }
    }

    // TODO: Uncomment all if screen needs to be filtered, add @searchInput="searchInput" to Select2 component
    // function searchInput(event) {
    //     console.log(event)
    // }


    // Focus the search input when opening select (search)
    function focusInput() {
        $('.select2-search__field')[0].focus()

        // iOS doesnt like focus
        if (isPlatformIOS) {
            $('input::-webkit-textfield-decoration-container').click()
        }
    }


    // On select2 select also select the radio value and scroll it into view
    function contactPersonSearchSelect(event) {
        selectedPerson.value = event.id
        $(`label[for=${event.id}]`)[0].scrollIntoView()
    }
    

    async function changeStepAndSubmit(stepNumber) {
        const dataToSend = {
            'call_off_cart_item_id': cartId.value,
            'contact_person_id': selectedPerson.value
        }

        const dataToSaveInStore = {
            'contact_person': {'id': selectedPerson.value}
        }

        await store.dispatch('calloffCreation/submitSecondStepData', dataToSend)
        store.commit('calloffCreation/saveStepData', dataToSaveInStore)
        
        await store.dispatch('calloffCreation/fetchCartData', route.params.id)
		await store.dispatch('calloffCreation/fetchContractItemData', route.params.id)

        emit('changeStep', stepNumber)
    }

    onUnmounted(() => {
        $('#calloff-contact-person-search').select2('open')
    })
</script>

<style lang="sass" scoped>
.step-card
    margin-bottom: 17px
    border-radius: 5px
    padding: 23px
    background-color: $white

    .contact-person-search-container
        margin-bottom: 16px

    .data-container
        max-height: 524px
        overflow-x: hidden

        .contact-person-card
            display: flex
            flex-direction: column

            input
                position: absolute
                opacity: 0
                visibility: hidden

                &:checked
                    + label
                        .checkbox-container
                            .active
                                display: inline-block !important

                            .inactive
                                display: none !important

            label
                .contact-person-container
                    display: flex
                    width: 100%
                    cursor: pointer
                    
                    .contact-person-data
                        width: 80%
                        padding-left: 7px
                        font-family: PoppinsMedium
                        font-size: 12.5px
                        letter-spacing: 0.06px
                        line-height: 18px

                        p
                            display: flex
                            align-items: center
                            margin: 6px 0px

                            img
                                margin-right: 10px


                    .checkbox-container
                        width: 20%
                        text-align: right

                        img
                            margin-top: 6px

                            &.active
                                display: none

                            &.inactive
                                display: inline-block

                .spacer
                    margin-top: 10px
                    margin-bottom: 10px
                    margin-left: -5%
                    border-top: 1px solid rgba(98, 112, 134, 0.5)
                    width: 110%

    .button-container
        display: flex
        justify-content: space-between
        margin-left: -5%
        margin-bottom: 8px
        width: 110%

        .next-button, .back-button
            width: 150px
            padding-top: 13px
            padding-bottom: 13px
            border: none
            border-radius: 3px
            font-family: PoppinsMedium
            font-size: 16px
            color: $white

        .back-button
            background-color: $gray-blue
            margin-right: 10px

        .next-button
            background-color: $green

            &.disabled
                background-color: $text-gray
                pointer-events: none

.dark-mode
    .step-card
        background-color: $text-black

        .data-container
            .contact-person-card
                .contact-person-container
                    .contact-person-data
                        p
                            color: $dark-text-color
</style>

<style lang="sass">
.contact-person-select-container
    .select2-selection__rendered
        width: 100% !important
        padding-top: 1px !important
        color: $text-lighter-gray !important
        
.contact-person-select-dropdown
    background-color: transparent

    &.select2-dropdown--below
        top: -36px

    &.select2-dropdown--above
        top: 37px
        display: flex
        flex-direction: column-reverse

    .select2-search--dropdown
        padding-left: 35px

        .select2-search__field
            font-family: PoppinsRegular
            font-size: 14px
            border: none
            color: $text-lighter-gray
            background-color: $white

        input::-webkit-search-decoration
            display: none

    .select2-results
        background-color: white

        .select2-results__options
            border-bottom-left-radius: 4px
            border-bottom-right-radius: 4px

            .select2-results__option
                padding-top: 10px
                padding-bottom: 10px
                padding-left: 12px
                font-family: PoppinsRegular
                font-size: 14px
                color: $placeholder-gray

                &[aria-selected=true]
                    background-color: $white

                &.select2-results__option--highlighted
                    background-color: $light-blue
                    color: $white

    &.select2-dark-mode
        .select2-search__field,
        .select2-results__message
            background-color: $dark-button-gray !important
            color: $dark-text-color !important

    &.ios-platform
        &.select2-dropdown--below
            top: -39px

.contact-person-search-container
    width: 100%
    margin-right: 3px

    .select-filter-box
        position: relative

        .select-filter-reset
            position: absolute
            top: 6px
            right: 10px
            cursor: pointer

    .select-filters-container
        text-align: left

        .select2-container
            width: 100% !important

            .select2-selection
                display: flex
                align-items: center
                height: 39px
                width: 100%

                background-image: url("@/assets/icons/search.svg")
                background-repeat: no-repeat
                background-position: 10px 9px

                .select2-selection__rendered
                    margin-left: 30px
                    width: 70%

                .select2-selection__arrow
                    display: none

.dark-mode
    .step-card
        .contact-person-select-container
            background-color: $dark-button-gray
            border: 1px solid $component-border
</style>