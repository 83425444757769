<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import Button from "@/components/Common/Button.vue"

const store = useStore()
const emit = defineEmits()

const isRequesting = computed(() => store.state.settings.isPasswordFormRequesting)

// Fields
const fields = ref({
    old_password: {
        required: true,
        hasError: false,
        input: '',
        type: 'password',
        label: 'on_site.profile.old_password_label'.trans()
    },
    new_password: {
        required: true,
        hasError: false,
        input: '',
        type: 'password',
        label: 'on_site.profile.new_password_label'.trans()
    },
    new_password_repeat: {
        required: true,
        hasError: false,
        input: '',
        type: 'password',
        label: 'on_site.profile.new_password_repeat_label'.trans()
    }
})

// Tips
const passwordTips = ref({
    length: {
        valid: false,
        regex: /.{8,}/,
        label: 'on_site.profile.minimal_8_letters'.trans()
    },
    number: {
        valid: false,
        regex: /[0-9]/,
        label: 'on_site.profile.minimal_one_number'.trans()
    },
    uppercase: {
        valid: false,
        regex: /[A-Z]/,
        label: 'on_site.profile.minimal_one_uppercase_letter'.trans()
    },
    lowercase: {
        valid: false,
        regex: /[a-z]/,
        label: 'on_site.profile.minimal_one_lowercase_letter'.trans()
    },
    special_char: {
        valid: false,
        regex: /[#!@$%^+'.,:;&§[{}*=`°_\-\]\?]/,
        label: 'on_site.profile.minimal_one_special_sign'.trans()
    }
})

// Buttons
const closeButtonText = 'on_site.profile.password_change.close_button'.trans()
const submitButtonText = 'on_site.profile.password_change.submit_button'.trans()

const checkPasswordTips = () => {
    const fieldValue = fields.value.new_password.input
    const tips = passwordTips.value

    let valid = true

    Object.keys(tips).forEach(tipName => {
        const tip = tips[tipName]
        const tipRegex = tip.regex

        if (fieldValue.match(tipRegex)) {
            tip.valid = true
        } else {
            tip.valid = false

            valid = false
        }
    })

    return valid
}

const changeInputType = fieldName => {
    const field = fields.value[fieldName]
    const fieldType = field.type

    if(fieldType === 'password') {
        field.type = 'text'
    } else {
        field.type = 'password'
    }
}

const submitForm = () => {
    const isValid = validateForm()

    if (isValid) {
        const payload = createPayload()

        store.dispatch('settings/updateUserPassword', payload)
    }
}

function createPayload() {
    const payload = {}
    const fieldValues = fields.value

    Object.keys(fieldValues).forEach(fieldName => {
        const fieldValue = fieldValues[fieldName].input

        payload[fieldName] = fieldValue
    })

    return payload
}

function validateForm() {
    let isFormValid = true

    const fieldValues = fields.value

    // Required fields
    Object.keys(fieldValues).forEach(fieldName => {
        const singleField = fieldValues[fieldName]

        let valid = true
        if(singleField.required && !singleField.input) {
            valid = false
        }

        singleField.hasError = !valid
        if (!valid) {
            isFormValid = false
        }
    })

    // Password repeat
    const passwordField = fieldValues.new_password
    const passwordRepeatField = fieldValues.new_password_repeat

    if(passwordField.input && passwordRepeatField.input && passwordField.input === passwordRepeatField.input) {
        passwordField.hasError = false
        passwordRepeatField.hasError = false
    } else {
        passwordField.hasError = true
        passwordRepeatField.hasError = true

        isFormValid = false
    }

    // All password tips are satisfied
    const areTipsValid = checkPasswordTips()
    if(!areTipsValid) {
        passwordField.hasError = true
        passwordRepeatField.hasError = true

        isFormValid = false
    }

    return isFormValid
}

const closeForm = () => {
    // Emit event
    emit('on-close')
}

</script>

<template>
    <div class="change-password-container">
        <div class="inputs-container">
            <div class="form-group material password">
                <input
                    id="old_password"
                    :class="{ 'has-error': fields.old_password.hasError }"
                    v-model="fields.old_password.input"
                    :type="fields.old_password.type"
                    :placeholder="fields.old_password.label"
                />
                <label for="name">{{ fields.old_password.label }}</label>
                <button class="password-change" @click="changeInputType('old_password')">
                    <img src="@/assets/icons/eye_off.svg">
                    <img src="@/assets/icons/eye.svg">
                </button>
            </div>
            <div class="form-group material password">
                <input
                    id="new_password"
                    :class="{ 'has-error': fields.new_password.hasError }"
                    v-model="fields.new_password.input"
                    :type="fields.new_password.type"
                    :placeholder="fields.new_password.label"
                    @keyup="checkPasswordTips"
                />
                <label for="name">{{ fields.new_password.label }}</label>
                <button class="password-change" @click="changeInputType('new_password')">
                    <img src="@/assets/icons/eye_off.svg">
                    <img src="@/assets/icons/eye.svg">
                </button>
            </div>
            <div class="form-group material password">
                <input
                    id="new_password_repeat"
                    :class="{ 'has-error': fields.new_password_repeat.hasError }"
                    v-model="fields.new_password_repeat.input"
                    :type="fields.new_password_repeat.type"
                    :placeholder="fields.new_password_repeat.label"
                />
                <label for="name">{{ fields.new_password_repeat.label }}</label>
                <button class="password-change" @click="changeInputType('new_password_repeat')">
                    <img src="@/assets/icons/eye_off.svg">
                    <img src="@/assets/icons/eye.svg">
                </button>
            </div>
        </div>

        <ul class="password-tips">
            <li
                class="tip"
                v-for="(value, name) in passwordTips"
                :key="name"
                :class="{ 'valid': value.valid }"
                v-html="value.label"
            />
        </ul>

        <div class="buttons-container">
            <Button
                class="save-profile-button gray-blue bold"
                @click="closeForm"
                :text="closeButtonText"
            />

            <Button
                class="save-profile-button light-blue bold"
                @click="submitForm"
                :text="submitButtonText"
                :isLoading="isRequesting"
            />
        </div>
    </div>
</template>