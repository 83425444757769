<template>
    <div class="password-recovery-page">
        <router-link :to="{ name: 'login' }">
            <img class="recovery-page-close" alt="Show password" src="@/assets/icons/close-gray.svg">
        </router-link>
        <h3 class="password-recovery-title">{{'on_site.forgot_password.title'.trans()}}</h3>
        <p class="password-recovery-text">{{'on_site.forgot_password.text'.trans()}}</p>

        <div class="invalid-recovery-data-warning hidden">
            <img class="hide-warning" alt="Hide" src="@/assets/icons/menu-close.svg" @click="hideWarning">
            {{'on_site.recovery.invalid_recovery_data'.trans()}}
        </div>

        <input class="standard-input" :class="{ 'warning': warnings.email.value }" v-model="eMail" :placeholder="placeholders.email" @input="removeWarning('email')"/>
        
        <button class="light-blue submit-password-recovery-button" @click="recoverPassword">
            {{'on_site.forgot_password.send_button'.trans()}}
            </button>
        <button class="transparent cancel-password-recovery-button">
            <router-link :to="{ name: 'login' }">{{'on_site.forgot_password.cancel_button'.trans()}}</router-link>
        </button>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import { showErrorPopup } from '@/utils/globalSwalPopups.js'
    import Swal from 'sweetalert2'

    const store = useStore()
    const router = useRouter()

    const placeholders = { email: 'on_site.login.placeholder.email'.trans() }
    const eMail = ref('')
    const warnings = { email: ref(false) }


    async function recoverPassword() {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        if (eMail.value.match(emailRegex)) {
            try {
                const url = Routing.generate('api-password-recovery', {}, true)
                let payload = new FormData()

                payload.append('email', eMail.value)

                await axios.post(url, payload)
                
                let swalClass = 'swal-popups-light'

                if(store.state.settings && store.state.settings.dark_mode_activated) {
                    swalClass = 'swal-popups-dark'
                }
            
                Swal.fire({
                    customClass: {
                        container: swalClass,
                    },
                    icon: 'success',
                    title: 'on_site.forgot_password.title'.trans(),
                    text: 'on_site.login.reset-password-success-request'.trans(),
                    confirmButtonText: 'on_site.login.reset-password-success-close'.trans()

                }).then(result => {
                    if (result.isConfirmed || result.isDismissed) {
                        router.push({ name: 'dashboard' })
                    }
                })

            } catch(error) {
                if (error.response.status == 503) {
                    router.push({ name: 'maintenance' })

                } else if (error.response.status == 403) {
                    $('.invalid-recovery-data-warning').removeClass('hidden')

                } else if (error.response.status == 404) {
                    showErrorPopup(
                        'on_site.password_recovery.fail_title'.trans(),
                        'on_site.password_recovery.fail_text'.trans(),
                        'on_site.password_recovery.fail_button_text'.trans()
                    )

                } else {
                    showErrorPopup()
                }
            }

        } else {
            warnings.email.value = true
        }
    }


    function removeWarning(fieldName) {
        warnings[fieldName].value = false
    }


    function hideWarning() {
        $('.invalid-recovery-data-warning').addClass('hidden')
    }


    // If already logged in, redirect do dashboard
    if (store.state.userLoggedIn) {
        router.push({ name: 'dashboard' })
    }
</script>

<style lang="sass" scoped>
.password-recovery-page
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    padding: 60px 0px
    background-color: $background-gray

    .recovery-page-close
        position: absolute
        top: 15px
        right: 15px

    .password-recovery-title
        margin: 0px
        font-family: PoppinsBold
        font-size: 24px
        color: $title-gray

    .password-recovery-text
        width: 250px
        color: $title-gray

    .invalid-recovery-data-warning
        position: relative
        width: 90%
        max-width: 800px
        margin: 10px auto 20px auto
        padding-top: 10px
        padding-bottom: 10px
        font-size: 14px
        color: $white
        background-color: $login-error-red
        border-radius: 4px

        .hide-warning
            position: absolute
            height: 10px
            padding: 10px
            top: -2px
            right: -2px

    .standard-input
        margin-top: 10px
        margin-bottom: 50px
        width: 250px
        padding-left: 20px

        &.warning
            border: 1px solid $bright-red

    .submit-password-recovery-button
        font-family: PoppinsMedium

    .cancel-password-recovery-button
        margin-top: 10px

        a
            text-decoration: none
            color: $names-gray

.dark-mode
    .password-recovery-page
        background-color: $text-black

        .password-recovery-title
            color: $dark-text-color

        .password-recovery-text, .cancel-password-recovery-button a
            color: $white
</style>