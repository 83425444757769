<template>
    <div class="step-card">
        <p class="input-name">{{'on_site.calloff_creation.incoterm'.trans()}}</p>
        <div class="incoterms">
            <div class="button-container incoterm-container" v-for="incoterm in availableIncoterms" :key="incoterm.id">
                <input type="radio" :id="incoterm.id" name="incoterm" :value="incoterm.id" v-model="selectedIncotermId">
                <label :for="incoterm.id">{{incoterm.title}}</label>
            </div>
        </div>

        <p class="input-name">{{'on_site.calloff_creation.vehicle'.trans()}}</p>
        <div class="vehicles">
            <div class="button-container vehicle-container" v-for="vehicle in availableTruckTypes" :key="vehicle.id">
                <input type="radio" :id="vehicle.id" name="vehicle" :value="vehicle.id" v-model="selectedVehicleId">
                <label :for="vehicle.id">{{vehicle.title}}</label>
            </div>
        </div>

        <p class="input-name">{{'on_site.calloff_creation.quantity'.trans()}} ({{quantityUnit}})</p>
        <div class="quantity-container">
            <input type="number" :placeholder="quantityPlaceholder" v-model="quantityValue"
                @keydown="validateInputCharacter"
                @blur="validateInputValue"
            >
        </div>
        <div class="quantity-warning" v-if="showQuantityWarning">
            <img src="@/assets/icons/error_warning.svg" alt="warning">
            {{'on_site.calloff_creation.quantity_warning'.trans()}}
        </div>

        <p class="input-name">{{'on_site.calloff_creation.quantity_hourly'.trans()}} ({{quantityUnit}}/h)</p>
        <div class="quantity-container hourly">
            <input type="number" :placeholder="quantityHourlyPlaceholder" v-model="quantityHourlyValue"
                @keydown="validateInputCharacter"
                @blur="validateHourlyInputValue"
            >
        </div>

        <p class="input-name">{{'on_site.calloff_creation.note'.trans()}}</p>
        <div class="note-container">
            <textarea :maxlength="noteMaxLength" v-model="noteText" :placeholder="'on_site.calloff_creation.note.placeholder'.trans()" @input="setNotesLength($event.target.value)"></textarea>
            <div class="character-counter-container" id="character-counter-container">{{noteText ? noteText.length : '0'}}/{{noteMaxLength}}</div>
        </div>

        <p class="input-name">{{'on_site.calloff_creation.night_shift'.trans()}}</p>
        <div class="night-shift-container" :class="{'night-shift-active': nightShiftValue}">
            <input type="checkbox" id="night-shift" v-model="nightShiftValue" @change="toggleNightShiftDependencies">
            <label for="night-shift" v-html="moonIcon"></label>
        </div>

        <p class="input-name">{{'on_site.calloff_creation.delivery_start'.trans()}}</p>
        <div class="delivery-container">
            <div class="flatpickr-container">
                <label for="date-start-input">
                    <img src="@/assets/icons/calendar-blue.svg" alt="calendar">
                </label>
                <flat-pickr id="date-start-input" class="date-input date-start-input" :config="datepickerStartConfig" v-model="dateStart"/>
            </div>
            <div class="flatpickr-container">
                <label for="time-start-input">
                    <img src="@/assets/icons/clock.svg" alt="clock">
                </label>
                <ClockTimePicker id="time-start-input" :options="clockTimePickerStartOptions" inputCssClass="calloff-creation-time-start-input" v-model="timeStart"/>
            </div>
        </div>

        <p class="input-name">{{'on_site.calloff_creation.delivery_end'.trans()}}</p>
        <div class="delivery-container">
            <div class="flatpickr-container">
                <label for="date-end-input">
                    <img src="@/assets/icons/calendar-blue.svg" alt="calendar">
                </label>
                <flat-pickr id="date-end-input" class="date-input date-end-input" :config="datepickerEndConfig" v-model="dateEnd"/>
            </div>
            <div class="flatpickr-container">
                <label for="time-end-input">
                    <img src="@/assets/icons/clock.svg" alt="clock">
                </label>
                <ClockTimePicker id="time-end-input" :options="clockTimePickerEndOptions" inputCssClass="calloff-creation-time-end-input" v-model="timeEnd"/>
            </div>
        </div>

        <button class="next-button" :class="{'disabled': goToStepTwoButtonDisabled}" @click="goToStepTwo">
            {{'on_site.calloff_creation.next_step'.trans()}}
        </button>
    </div>
</template>

<script setup>
    import { computed, reactive, ref, watch } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'
    import { moonIcon } from '@/utils/svgIcons'
    import flatPickr from 'vue-flatpickr-component'
    import { German } from 'flatpickr/dist/l10n/de'
    import moment from 'moment'
    import ClockTimePicker from '@/components/ClockTimePicker/ClockTimePicker.vue'

    const store = useStore()
    const route = useRoute()
    const emit = defineEmits(['changeStep'])
    const darkModeActivated = computed(() => store.state.settings && store.state.settings.dark_mode_activated)

    const cartId = ref(store.state.calloffCreation.data.id)

    const availableIncoterms = ref(store.state.calloffCreation.data.available_incoterms)
    const availableTruckTypes = ref(store.state.calloffCreation.data.truck_type_options)

    const selectedIncotermId = ref(store.state.calloffCreation.data.incoterm ? store.state.calloffCreation.data.incoterm.id : '')
    const selectedVehicleId = ref(store.state.calloffCreation.data.truck_type ? store.state.calloffCreation.data.truck_type.id : '')

    const selectedIncotermName = computed(() => store.state.calloffCreation.data.available_incoterms.filter(x => x.id == selectedIncotermId.value)[0].title)
    const selectedTruckTypeName = computed(() => store.state.calloffCreation.data.truck_type_options.filter(x => x.id == selectedVehicleId.value)[0].title)

    // We need quantityValueCached to save the quantityValue after the blur because it gets deleted in order to show placeholder
    const quantityValue = ref(null)
    const quantityValueCached = ref(store.state.calloffCreation.data.cart_item_quantity_raw)
    const quantityUnit = ref(store.state.calloffCreation.data.cart_item_quantity_unit)
    const quantityLimit = ref(store.state.calloffCreation.data.amount_warning_limit_raw)
    const quantityPlaceholder = ref(store.state.calloffCreation.data.cart_item_quantity.trim())
    const showQuantityWarning = computed(() => quantityValueCached.value > quantityLimit.value)

    const quantityHourlyValue = ref(null)
    const quantityHourlyValueCached = ref(store.state.calloffCreation.data.hourly_amount_raw)
    const quantityHourlyPlaceholder = ref(store.state.calloffCreation.data.hourly_amount.trim())

    // initially it can be null coming from backend, thats why we manually set it to empty here in that case
    const noteText = ref(store.state.calloffCreation.data.note ? store.state.calloffCreation.data.note : '')
    const noteMaxLength = computed(() => store.state.configuration.configurationData.note_max_letters ? store.state.configuration.configurationData.note_max_letters : 255)

    const calloffIsBinding = ref(true)

    // initially it can be null coming from backend, thats why we manually set it to false here in that case
    const nightShiftValue = ref(store.state.calloffCreation.data.night_shift ? store.state.calloffCreation.data.night_shift : false)

    const dateStart = ref(store.state.calloffCreation.data.date_start_pretty)
    const dateEnd = ref(store.state.calloffCreation.data.date_end_pretty)
    const timeStart = ref(store.state.calloffCreation.data.date_start_time)
    const timeEnd = ref(store.state.calloffCreation.data.date_end_time)
    const timeEndMinDate = ref(dateStart.value && timeStart.value ? timeStart.value : '')

    const dateStartFormatted = computed(() => flatpickr.formatDate(flatpickr.parseDate(dateStart.value, 'D, d.m.y'), 'd.m.Y'))
    const dateEndFormatted = computed(() => flatpickr.formatDate(flatpickr.parseDate(dateEnd.value, 'D, d.m.y'), 'd.m.Y'))

    // if the default (from backend) date start is older than today, empty it so that the datepicker doesnt break (when you open it but dont select anything so it takes that bad value)
    if (dateStart.value) {
        if (moment(dateStartFormatted.value, 'DD.MM.YYYY').add(1, 'days') < moment()) {
            dateStart.value = ''
        }
    }

    const dateStartNextDay = computed(() => flatpickr.formatDate(flatpickr.parseDate(dateStart.value, 'D, d.m.y').fp_incr(1), 'D, d.m.y', German))
    const isSameDay = computed(() => dateStart.value && (dateStart.value == dateEnd.value))
    const wrongHours = computed(() => timeStart.value && timeEnd.value && (moment(timeStart.value, 'HH:mm') > moment(timeEnd.value, 'HH:mm')))

    const goToStepTwoButtonDisabled = computed(() => {
        const calloffPlanningValidation = !selectedIncotermId.value || !selectedVehicleId.value || !quantityValueCached.value || !dateStart.value || !dateEnd.value

        if (!calloffIsBinding.value) return calloffPlanningValidation
        else return calloffPlanningValidation || !timeStart.value || !timeEnd.value
    })


    // Global configuration for the datepickers
    const datepickerGlobalConfig = {
        locale: German,
        dateFormat: 'D, d.m.y',
        minDate: 'today',
        disableMobile: true,
        onReady: (_, __, fp) => {
            $(fp.calendarContainer).addClass('default-flatpickr-style')

            if (darkModeActivated.value) $(fp.calendarContainer).addClass('dark-mode-style')
        },
        onDayCreate: function(dObj, dStr, fp, dayElem) {
            const dayOfWeek = dayElem.dateObj.getDay()
            const isWeekend = (dayOfWeek === 6) || (dayOfWeek  === 0)

            if (isWeekend) {
                dayElem.classList.add('weekendDay')
            }
        }
    }


    // Configuration for date start datepicker
    const datepickerStartConfig = ref({
        ...datepickerGlobalConfig,
        onChange: (date) => {
            if (calloffIsBinding.value && nightShiftValue.value) {
                dateEnd.value = dateStartNextDay.value

            } else if (calloffIsBinding.value) {
                dateEnd.value = flatpickr.formatDate(flatpickr.parseDate(dateStart.value, 'D, d.m.y'), 'D, d.m.y', German)
            }

            if (isSameDay.value && wrongHours.value) {
                timeEnd.value = timeStart.value
            }
        }
    })


    // Configuration for date end datepicker
    const datepickerEndConfig = ref({
        ...datepickerGlobalConfig,
        minDate: computed(() => dateStart.value ? dateStart.value : 'today'),
        enable: computed(() => {
            if (calloffIsBinding.value && nightShiftValue.value) {
                return dateStart.value ? [dateStart.value, dateStartNextDay.value] : []

            } else if (calloffIsBinding.value) {
                return dateStart.value ? [dateStart.value] : []

            } else {
                return [() => true]
            }
        }),
        onChange: () => {
            if (isSameDay.value) {
                timeEndMinDate.value = timeStart.value

                if (wrongHours.value) {
                    timeEnd.value = timeStart.value
                }

            } else {
                timeEndMinDate.value = ''
            }
        }
    })


    // Configuration used for both timepickers
    const clockTimePickerGlobalOptions = {
        alwaysSelectHoursFirst: true,
        precision: 5,
        dropdownCssClass: `calloff-creation-timepicker-dropdown ${darkModeActivated.value ? 'dark-mode' : ''}`,
        i18n: {
            cancelButton: 'ABBRECHEN',
            okButton: 'OK'
        },
    }


    // Configuration for time start timepicker
    const clockTimePickerStartOptions = ref({
        ...clockTimePickerGlobalOptions,
        defaultTime: '07:00',
        onChange: function() {
            if (isSameDay.value && timeStart.value) {
                timeEndMinDate.value = timeStart.value

                if (wrongHours.value) timeEnd.value = timeStart.value

            } else {
                timeEndMinDate.value = ''
            }
        }
    })


    // Configuration for time end timepicker
    const clockTimePickerEndOptions = ref({
        ...clockTimePickerGlobalOptions,
        minimum: computed(() => timeEndMinDate.value),
        defaultTime: '15:00',
        onChange: function() {
            if (isSameDay.value && wrongHours.value) {
                timeEnd.value = timeStart.value
            }
        }
    })


    // Forbid the input of decimal signs . and , on quantity inputs, also + and - signs
    // Trigger blur on pressing enter
    function validateInputCharacter(event) {
        if (event.keyCode == 13 || event.key == 'Enter') {
            $(event.target).trigger('blur')
        }

        const forbiddenCharacterCodes = [107, 108, 109, 110, 187, 188, 189, 190]
        const forbiddenCharacters = [',', '.', '+', '-']

        if (forbiddenCharacterCodes.includes(event.keyCode) || forbiddenCharacters.includes(event.key)) {
            event.preventDefault()
            event.stopPropagation()
        }
    }


    // Logic for interactions between quantity and hourly quantity inputs
    function validateInputValue() {
        if (quantityValue.value && quantityValue.value >= 1) {
            
            // update the placeholder, save the value, then delete it in order for placeholder to appear
            quantityPlaceholder.value = `${parseFloat(quantityValue.value).toLocaleString('de-DE')} ${quantityUnit.value}`
            quantityValueCached.value = quantityValue.value
            quantityValue.value = null

            if (quantityHourlyValueCached.value > parseInt(quantityValueCached.value)) {
                quantityHourlyValueCached.value = null
                quantityHourlyPlaceholder.value = ''
            }
        } else {
            quantityValue.value = null
        }
    }

    function validateHourlyInputValue() {
        if (quantityHourlyValue.value && quantityHourlyValue.value >= 1) {

            if (quantityHourlyValue.value > parseInt(quantityValueCached.value)) {
                quantityHourlyValue.value = null

            } else {
                // update the placeholder, save the value, then delete it in order for placeholder to appear
                quantityHourlyPlaceholder.value = `${parseFloat(quantityHourlyValue.value).toLocaleString('de-DE')} ${quantityUnit.value}/h`
                quantityHourlyValueCached.value = quantityHourlyValue.value
                quantityHourlyValue.value = null
            }

        } else {
            quantityHourlyValue.value = null
        }
    }


    // When the night shift value is changed, also set the appropriate end date
    function toggleNightShiftDependencies() {
        if (calloffIsBinding.value && dateStart.value) {
            if (nightShiftValue.value) {
                dateEnd.value = dateStartNextDay.value
                timeEndMinDate.value = ''

            } else {
                dateEnd.value = dateStart.value
                timeEndMinDate.value = timeStart.value
            }
        }
    }

    function setNotesLength(text) {
        const characterCounter = document.querySelector('#character-counter-container')
        const newLengthString = text.length + '/' + noteMaxLength.value

        characterCounter.innerHTML = newLengthString
    }

    async function goToStepTwo() {
        const dataToSend = {
            'contract_item_id': route.params.id,
            'incoterm': selectedIncotermId.value,
            'truck_type': selectedVehicleId.value,
            'amount': quantityValueCached.value,
            'hourly_amount': quantityHourlyValueCached.value,
            'delivery_start_date': dateStartFormatted.value,
            'delivery_start_time': timeStart.value,
            'delivery_end_date': dateEndFormatted.value,
            'delivery_end_time': timeEnd.value,
            'note': noteText.value,
            'night_shift': nightShiftValue.value,
            'call_off_cart_item_id': cartId.value
        }

        // needed because the data in response (saved in store) isnt named the same as in this submit request
        const dataToSaveInStore = {
            'incoterm': {'id': selectedIncotermId.value, 'title': selectedIncotermName.value},
            'truck_type': {'id': selectedVehicleId.value, 'title': selectedTruckTypeName.value},
            'cart_item_quantity_raw': quantityValueCached.value,
            'cart_item_quantity': quantityPlaceholder.value,
            'hourly_amount_raw': quantityHourlyValueCached.value,
            'hourly_amount': quantityHourlyPlaceholder.value,
            'date_start': dateStartFormatted.value,
            'date_start_pretty': dateStart.value,
            'date_start_time': timeStart.value,
            'date_end': dateEndFormatted.value,
            'date_end_pretty': dateEnd.value,
            'date_end_time': timeEnd.value,
            'note': noteText.value,
            'night_shift': nightShiftValue.value
        }

        await store.dispatch('calloffCreation/submitFirstStepData', dataToSend)
        store.commit('calloffCreation/saveStepData', dataToSaveInStore)

        emit('changeStep', 2)
    }
</script>

<style lang="sass" scoped>
.step-card
    margin-bottom: 17px
    padding: 15px 10px 32px 10px
    border-radius: 5px
    background-color: $white

    .input-name
        margin: 0px
        margin-bottom: 5px
        font-family: PoppinsSemiBold
        font-size: 12px
        letter-spacing: 0.02px
        text-align: left
        color: $text-blue

    .incoterms,
    .vehicles
        display: flex
        flex-wrap: wrap
        margin-bottom: 12px

        .button-container
            input
                position: absolute
                opacity: 0
                visibility: hidden

                &:checked
                    + label
                        color: $white
                        background-color: $button-blue
                        border-color: $button-blue

            label
                display: inline-block
                padding: 9px 12px
                margin-bottom: 8px
                border: 1px solid rgba(98, 112, 134, 0.5)
                border-radius: 5px
                font-size: 13px
                letter-spacing: 0.03px
                color: $text-lighter-gray
                
                &:hover
                    color: $white
                    background-color: $button-blue
                    border-color: $button-blue
                    cursor: pointer

            &:not(:last-child)
                label
                    margin-right: 8px

    .quantity-container,
    .note-container
        text-align: left

        input, textarea
            width: 100%
            margin-bottom: 20px
            padding: 11px 12px
            border: 1px solid rgba(98, 112, 134, 0.5)
            border-radius: 5px
            resize: none
            font-family: PoppinsRegular
            font-size: 14px
            letter-spacing: 0.03px
            color: $text-black

            /* Hide placeholder on input focus */
            &:focus::-webkit-input-placeholder
                color: transparent !important
            &:focus::-moz-placeholder
                color: transparent !important
            &:focus:-moz-placeholder
                color: transparent !important
            &:focus::placeholder
                color: transparent !important
            
            /* Change the color of placeholder */
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            &::placeholder
                color: $text-black
                opacity: 1
            &:-ms-input-placeholder
                color: $text-black
            &::-ms-input-placeholder
                color: $text-black

        /* Remove arrows from input type number */
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button
            -webkit-appearance: none
            margin: 0
        /* Firefox */
        input[type=number]
            -moz-appearance: textfield

    .note-container
        position: relative

        textarea
            min-height: 110px
            margin-bottom: 13px

        .character-counter-container
            position: absolute
            bottom: -10px
            right: 10px
            font-size: 14px
            letter-spacing: 0.03px
            color: $text-lighter-gray

    .night-shift-container
        text-align: left
        margin-bottom: 9px

        input
            position: absolute
            opacity: 0
            visibility: hidden

    .delivery-container
        display: flex
        margin-bottom: 20px

        .flatpickr-container
            display: flex
            align-items: center

            label
                cursor: pointer

                img
                    height: 18px
                    margin-bottom: -3px
                    margin-left: 2px
                    margin-right: 10px
                    width: auto

            input
                width: 100%
                border: none
                font-family: PoppinsRegular
                font-size: 14px
                letter-spacing: 0.03px
                color: $text-black

    .quantity-warning
        display: flex
        margin-top: -15px
        margin-bottom: 15px
        font-size: 12px

        img
            margin-right: 5px
            width: 13px
            height: auto

    .next-button
        width: 100%
        padding-top: 13px
        padding-bottom: 13px
        margin-top: 10px
        border: none
        border-radius: 3px
        font-family: PoppinsMedium
        font-size: 16px
        color: $white
        background-color: $green

        &.disabled
            background-color: $text-gray
            pointer-events: none

.dark-mode
    .step-card
        background-color: $text-black

        .input-name
            color: $dark-text-color

        .incoterms,
        .vehicles
            .button-container
                label
                    background-color: $dark-button-gray
                    color: $white

        .quantity-container,
        .note-container
            input, textarea
                background-color: $dark-button-gray
                color: $white

                /* Change the color of placeholder */
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                &::placeholder
                    color: $white
                &:-ms-input-placeholder
                    color: $white
                &::-ms-input-placeholder
                    color: $white

            .character-counter-container
                color: $white

        .delivery-container
            input
                background-color: $text-black
                color: $white

        .quantity-warning
            color: $dark-text-color
</style>

<style lang="sass">
.calloff-creation-main-central-content
    .night-shift-container
        label
            svg
                cursor: pointer

                .b
                    fill: $white
                    stroke: $button-blue

                // enable hover only on devices that support it
                // because on touch devices that dont support hover, the hover effect persists after touch
                @media (hover: hover)
                    &:hover
                        .b
                            fill: $button-blue
                            transition: all 0.2s ease-in-out

        &.night-shift-active
            label
                svg
                    .b
                        fill: $button-blue

                    @media (hover: hover)
                        &:hover
                            .b
                                fill: $green
                                transition: all 0.2s ease-in-out

.dark-mode
    .calloff-creation-main-central-content
        .night-shift-container
            svg
                .b
                    stroke: $text-black
</style>