<script setup>
	import Footer from '@/components/Common/Footer.vue'
	import Loader from '@/components/Common/Loader.vue'

	import List from '@/components/Messages/List.vue'
	import MessagesHeader from '@/components/Messages/MessagesHeader.vue'

	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import { computed } from 'vue'

	const store = useStore()
	const router = useRouter()

	const userName = computed(() => store.state.user.data ? store.state.user.data.name : null)

	const listData = computed(() => store.state.messages.listData)
	const hasNotificationsSeen = computed(() => store.state.messages.listData.items.length > 0)

	const notificationsNew = computed(() => store.state.messages.notificationsNew)
	const hasNotificationsNew = computed(() => store.state.messages.notificationsNew.items.length > 0)

	const hasResults = computed(() => hasNotificationsSeen.value || hasNotificationsNew.value)

	const isPageRequesting = computed(() => store.state.messages.isListPageRequesting)
	const isListRequesting = computed(() => store.state.messages.isListPagePaginationRequesting)

	store.commit('setQuasiOverlayStatus', {'messagesIsOpen': true})

	function markAllAsSeen() {
		store.dispatch('messages/markAllAsSeen')
	}
</script>

<template>
	<div class="messages-page" :class="{ 'has-results': hasResults }">

		<MessagesHeader :pageTitle="'on_site.page-title.messages'.trans()" :messagesPage="true"/>

		<div class="messages-container">
			<Loader v-if="isPageRequesting"/>

			<template v-else-if="hasResults">
				<template v-if="hasNotificationsNew">
					<button class="message-button" @click="markAllAsSeen">
						{{'on_site.messages.read_all'.trans()}}
					</button>
					<p class="list-title new-messages">
						{{'on_site.messages.new_messages'.trans()}}
					</p>
					<List :listData="notificationsNew" :isRequesting="isPageRequesting"/>
				</template>

				<template v-if="hasNotificationsSeen">
					<p class="list-title old-messages">
						{{'on_site.messages.seen_messages'.trans()}}
					</p>
					<List :listData="listData" :isRequesting="isListRequesting"/>
				</template>
			</template>

			<div class="no-results-container" v-else>
				<p class="username">{{'on_site.messages.hello'.trans()}} {{ userName }}!</p>
				<p class="info">{{'on_site.messages.no_messages'.trans()}}</p>
				<div class="icons-container">
					<img class="bell" src="@/assets/icons/bell-gray.svg" alt="bell"/>
					<img class="close" src="@/assets/icons/close-red.svg" alt="X"/>
					<img class="truck" src="@/assets/icons/truck.svg" alt="truck"/>
				</div>
			</div>
		</div>
		<Footer/>
	</div>
</template>

<style lang="sass" scoped>
.messages-page
	background-color: $white
	box-shadow: 0 3px 6px #01000029

	padding-top: 51px
	padding-bottom: 81px
	min-height: calc(100vh - 55px)

	&.has-results
		background-color: $button-background-gray

	.loader-container
		padding-top: 10vh

	.message-button
		width: calc(100% - 24px)
		margin: 5px 10px 0px 10px
		padding: 10px
		border: none
		border-radius: 3px
		font-family: PoppinsSemiBold
		font-size: 15px
		background-color: $button-blue
		color: $white
		box-shadow: 0 2px 2px #00000012

	.list-title
		margin: 10px 0px 0px 25px
		text-align: left
		font-family: PoppinsBold
		font-size: 13px

		&.new-messages
			color: $red

		&.old-messages
			color: $text-blue

	.no-results-container
		padding-top: 100px
		padding-left: 30px
		padding-right: 30px

		.username
			font-family: "MontserratMedium"
			font-size: 20px
			line-height: 19px
			color: $text-dark-gray

			margin-top: 0
			margin-bottom: 15px

		.info
			font-family: "MontserratMedium"
			font-size: 16px
			line-height: 19px
			color: $text-dark-gray

			margin-bottom: 25px

		.icons-container
			position: relative
			width: 230px
			margin: 0px auto

			.close
				position: absolute
				right: 60px
				top: 0px

			.bell
				position: absolute
				left: 85px

			.truck
				position: absolute
				top: 90px
				left: 0px
				width: 230px
				height: auto

.dark-mode
	.messages-page
		background-color: $text-black

		.list-title
			&.old-messages
				color: $button-blue
		
		.no-results-container
			.username, .info
				color: $white
</style>