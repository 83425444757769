<template>
  <div>
    <select class="form-control" :id="id" :name="name" :disabled="disabled" :required="required"></select>
  </div>
</template>

<script>
import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.min.css'

export default {
  name: 'Select2',
  data() {
    return {
      select2: null
    };
  },
  emits: ['opening', 'open', 'closing', 'close', 'selecting', 'select', 'clearing', 'clear', 'searchInput'],
  props: {
    modelValue: [String, Array],
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => { return { 'filterWithoutAjax': false } }
    },
  },
  watch: {
    options: {
      handler(val) {
        this.setOption(val);
      },
      deep: true
    },
    modelValue: {
      handler(val) {
        this.setValue(val);
      },
      deep: true
    },
  },
  methods: {
    setOption(val = []) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: val
      });
      this.setValue(this.modelValue);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger('change');
    }
  },
  mounted() {
    this.select2 = $(this.$el)
      .find('select')
      .select2({
        placeholder: this.placeholder,
        ...this.settings,
        data: this.options
      })
      .on('select2:select', ev => {
        this.$emit('select', ev['params']['data']);
      }).on('select2:selecting', ev => {
        this.$emit('selecting', ev['params']['data']);
      }).on('select2:open', ev => {
        this.$emit('open', ev['params']['data']);
      }).on('select2:close', ev => {
        this.$emit('close', ev['params']['data']);
      }).on('select2:opening', ev => {
        this.$emit('opening', ev['params']['data']);
      }).on('select2:closing', ev => {
        this.$emit('closing', ev['params']['data']);
      }).on('select2:clearing', ev => {
        this.$emit('clearing', ev['params']['data']);
      }).on('select2:clear', ev => {
        this.$emit('clear', ev['params']['data']);
      });

    // Custom emit for getting the search term with no ajax
    const component = this
    if (this.settings.filterWithoutAjax && this.settings.dropdownCssClass) {
      $(document).on('keyup', `.${this.settings.dropdownCssClass} .select2-search__field`, function() {
        component.$emit('searchInput', $(this).val())
      })
    }
    this.setValue(this.modelValue);
  },
  beforeUnmount() {
    this.select2.select2('destroy');
  }
};
</script>
