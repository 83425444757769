<template>
	<div class="calloff-card" :class="{'calloff-creation-card': creationCard}">
		<div class="calloff-header">
			<div class="calloff-material">
				<img alt=" " src="../../assets/icons/amount-dark-gray.svg"/>
				<span class="title">{{ "on_site.daily_amount.material".trans() }}</span>
				<span class="name">{{ materialName ? materialName : '—' }}</span>
			</div>

			<div class="calloff-table-container">
				<table class="calloff-material-table">
					<tr>
						<td class="material">{{ "on_site.daily_amount.material_number".trans() }}</td>
						<td>{{ materialNumber ? materialNumber : '—' }}</td>
					</tr>
					<tr>
						<td class="material">{{ "on_site.daily_amount.factory".trans() }}</td>
						<td>
							<span v-for="(factoryNum, index) in factoryNumberArray"
								:key="factoryNum.id">
								{{ factoryNum ? factoryNum + ' — ' + factoryNameArray[index] : '—' }}<br/>
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>

		<div class="spacer"></div>

		<div class="calloff-bottom-container">
			<div class="calloff-amounts-table-container">
				<p class="amounts-title" v-if="creationCard">{{ "on_site.calloffs.amounts-title".trans() }}</p>
				<table class="calloff-table">
					<tr>
						<td class="icon-container">
							<img alt=" " src="../../assets/icons/circle-green-full.svg"/>
							{{ "on_site.calloffs.amount".trans() }}
						</td>
						<td >{{ amount ? `${amountFormatted} ${quantityUnit}` : `0 ${quantityUnit}` }}</td>
					</tr>
					<tr>
						<td class="icon-container">
							<img alt=" " src="../../assets/icons/circle-gray-full.svg"/>
							{{ "on_site.calloffs.delivered".trans() }}
						</td>
						<td>{{ deliveredAmount ? `${deliveredAmountFormatted} ${quantityUnit}` : '—' }}</td>
					</tr>
					<tr>
						<td class="icon-container open-amount">
							<img alt=" " src="../../assets/icons/circle-blue-full.svg"/>
							{{ "on_site.calloffs.open".trans() }}
						</td>
						<td class="open-amount">{{ openAmount ? `${openAmountFormatted} ${quantityUnit}` : '—' }}</td>
					</tr>
				</table>
			</div>

			<div class="calloff-button-container" v-if="!creationCard">
				<router-link :to="{ name: 'calloff-creation', params: { 'id': contractItemId } }" class="calloff-creation-button light-blue">
					{{ "on_site.calloffs.go-to-calloff-creation".trans() }}
					<img alt=" " src="../../assets/icons/go-to-arrow.svg"/>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed, ref } from 'vue'
	import { useStore } from 'vuex'
	
	const store = useStore()

	const props = defineProps({
		creationCard: {
			type: Boolean,
			default: false
		},
		contractItemId: Number,
		materialName: String,
		materialNumber: String,
		deliveredAmount: String,
		openAmount: String,
		factoryName: {
			type: String,
			default: ''
		},
		factoryNumber: {
			type: String,
			default: ''
		},
		quantityUnit: String
	})

	const factoryNumberArray = props.factoryNumber.split(", ")
	const factoryNameArray = props.factoryName.split(", ")

	const amount = parseInt(props.deliveredAmount) + parseInt(props.openAmount)
	const amountFormatted = amount.toLocaleString('de-DE')
	const deliveredAmountFormatted = parseInt(props.deliveredAmount).toLocaleString('de-DE')
	const openAmountFormatted = parseInt(props.openAmount).toLocaleString('de-DE')
</script>

<style lang="sass" scoped>
.calloff-creation-card
	filter: none !important
	background-color: $background-gray !important

.calloff-card
	border-radius: 3px
	width: 94%
	min-width: 250px
	max-width: 408px
	min-height: 189px
	padding: 15px 10px 10px 10px
	background-color: $white
	margin: 5px 8px 8px 8px
	filter: drop-shadow(0px 3px 3px #01000029)
	box-sizing: border-box
	text-align: left

	.calloff-header
		.calloff-material
			.title, .name
				margin-left: 10px
				font-family: PoppinsSemiBold
				font-size: 15px
				letter-spacing: 0.07px
				line-height: 23px
				color: $text-lighter-gray

		.calloff-table-container
			font-family: PoppinsMedium
			font-size: 12px
			letter-spacing: 0.06px
			line-height: 18px

			.material
				padding-right: 10px

	.calloff-table-container
		.calloff-material-table
			.material
				vertical-align: top

	.spacer
		margin-top: 15px
		margin-bottom: 10px
		border-top: 1px solid rgba(98, 112, 134, 0.5)

	.calloff-bottom-container
		display: flex

		.calloff-amounts-table-container
			.amounts-title
				margin: 0
				font-family: PoppinsMedium
				font-size: 12px
				letter-spacing: 0.02px
				line-height: 16px
				color: $text-gray

			.calloff-table
				font-family: PoppinsMedium
				font-size: 13px
				letter-spacing: 0.03px
				line-height: 22px

				.icon-container
					padding-right: 30px

				.open-amount
					color: $button-blue

		.calloff-button-container
			display: flex
			margin-left: auto

			.calloff-creation-button
				display: flex
				justify-content: center
				align-items: center
				margin-top: auto
				border-radius: 5px
				height: 32px
				width: 81px
				font-family: PoppinsMedium
				font-size: 12px
				letter-spacing: 0.02px

				img
					margin-left: 10px
					height: 18px

.dark-mode
	.calloff-creation-card
		background-color: $text-black !important
		border: none !important

	.calloff-card	
		background-color: $dark-button-gray
		border: 1px solid $component-border
		color: $white

		.calloff-header
			.calloff-material
				.title, .name
					color: $gray-blue

		.calloff-bottom-container
			.calloff-amounts-table-container
				.calloff-table
					.open-amount
						color: $light-blue
</style>