<template>
    <div class="calloff-creation-page">
        <Header :pageTitle="pageTitle"/>
        <SubHeader :currentPage="currentPage"/>

        <Loader v-if="isRequesting"/>
        <template v-else>
            <div class="calloff-creation-top-container">
                <div class="calloff-creation-title">
                    <img alt="Green" src="../../assets/icons/icon-binding-request.svg"/>
                    <span>{{ 'on_site.calloffs.binding-request'.trans() }}</span>
                </div>

                <ContractItemCard
                    :creationCard="true"
                    :key="contractItemCardData.contract_item_id"

                    :contractItemId="contractItemCardData.contract_item_id"
                    :materialName="contractItemCardData.material_designation"
                    :materialNumber="contractItemCardData.material_number"
                    :deliveredAmount="contractItemCardData.delivered_quantity_raw"
                    :openAmount="contractItemCardData.remaining_quantity_raw"
                    :factoryName="contractItemCardData.factory_name"
                    :factoryNumber="contractItemCardData.factory_number"
                    :quantityUnit="contractItemCardData.quantity_unit"
                />
            </div>

            <div class="calloff-creation-main-container">
                <div class="calloff-creation-main-central-content">
                    <p class="step-name">{{ stepName }}</p>
                    <component @changeStep="changeStep" :is="currentStepComponent"/>
                </div>
            </div>
        </template>

        <Footer/>
    </div>
</template>

<script setup>
import Header from '@/components/Common/Header.vue'
import SubHeader from '@/components/SubHeader/SubHeader.vue'
import Footer from '@/components/Common/Footer.vue'
import Loader from '@/components/Common/Loader.vue'
import ContractItemCard from '@/components/CallOffs/ContractItemCard.vue'
import CalloffCreationStepOne from '@/components/CalloffCreation/CalloffCreationStepOne.vue'
import CalloffCreationStepTwo from '@/components/CalloffCreation/CalloffCreationStepTwo.vue'
import CalloffCreationStepThree from '@/components/CalloffCreation/CalloffCreationStepThree.vue'
import {ref, shallowRef, computed} from 'vue'
import {useRoute} from 'vue-router'
import {useStore} from 'vuex'

const store = useStore()
const route = useRoute()

const pageTitle = computed(() => store.state.contracts.selectedContract.contract_name)
const currentPage = 'on_site.menu.calloff_creation'.trans()

const stepName = ref('on_site.calloff_creation.step_1_name'.trans())
const currentStepComponent = shallowRef(CalloffCreationStepOne)

const contractItemCardData = computed(() => store.state.calloffCreation.contractItemData)
const isRequesting = computed(() => store.getters['calloffCreation/isRequesting'])


// Logic for changing steps, depends on the number that child component emitted
function changeStep(stepNumber) {
    switch (stepNumber) {
        case 1:
            currentStepComponent.value = CalloffCreationStepOne
            break
        case 2:
            currentStepComponent.value = CalloffCreationStepTwo
            break
        case 3:
            currentStepComponent.value = CalloffCreationStepThree
            break
        default:
            currentStepComponent.value = CalloffCreationStepOne
    }
}
</script>

<style lang="sass" scoped>
.calloff-creation-page
    padding-top: 51px + 48px
    // header + subheader
    padding-bottom: 51px
    // footer
    min-height: calc(100vh - 51px - 48px - 81px)

    .calloff-creation-top-container
        max-width: 374px
        margin: 0px auto 0px auto

        .calloff-creation-title
            margin-left: 25px
            margin-top: 10px
            text-align: left

            img
                max-height: 20px
                margin-right: 10px
                    max-width: 20px
                    position: relative
                    top: 5px

            span
                font-family: PoppinsSemiBold
                font-size: 15px
                letter-spacing: 0.07px
                line-height: 23px
                text-transform: uppercase
                color: $green

    .calloff-creation-main-container
        background-color: $text-blue
        min-height: calc(100vh - 417px)

        .calloff-creation-main-central-content
            max-width: 374px
            margin: 0px auto 0px auto
            padding: 10px

            .step-name
                margin: 1px 0px 7px 6px
                font-family: PoppinsMedium
                font-size: 12px
                letter-spacing: 0.02px
                text-align: left
                color: $white
</style>