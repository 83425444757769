<template>
	<div class="main-menu">
		<a class="menu-button"
			v-if="route.name == 'dashboard'"
			:href="router.resolve({ name: 'dashboard'}).href"
			@click="closeMenuAndShowLoader('contracts/setContractsRequestingFlag')"
		>
			{{ "on_site.menu.home".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/home.svg"/>
		</a>
		<router-link :to="{ name: 'dashboard' }" class="menu-button" v-else>
			{{ "on_site.menu.home".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/home.svg"/>
		</router-link>
    	<div class="spacer"></div>

		<a class="menu-button"
			v-if="route.name == 'construction-site'"
			:href="router.resolve({ name: 'construction-site'}).href"
			@click="closeMenuAndShowLoader('contracts/setContractsRequestingFlag')"
		>
			{{ "on_site.menu.construction_site".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/construction-site.svg"/>
		</a>
		<router-link :to="{ name: 'construction-site' }" class="menu-button" v-else>
			{{ "on_site.menu.construction_site".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/construction-site.svg"/>
		</router-link>
		<div class="spacer"></div>

		<template v-if="buttonPermissions.includes('qr-code-scanner')">
			<router-link :to="{ name: 'qr-scanner' }" class="menu-button">
				{{ "on_site.menu.qr_scanner".trans() }}
				<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/qr-code-scanner.svg"/>
			</router-link>
			<div class="spacer"></div>
		</template>

		<router-link :to="{ name: 'messages' }" class="menu-button">
			{{ "on_site.menu.messages".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/notifications.svg"/>
		</router-link>
		<div class="spacer"></div>

		<a class="menu-button"
			v-if="route.name == 'settings'"
			:href="router.resolve({ name: 'settings'}).href"
			@click="closeMenuAndShowLoader('settings/setRequestingFlag')"
		>
			{{ "on_site.menu.settings".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/settings.svg"/>
		</a>
		<router-link :to="{ name: 'settings' }" class="menu-button" v-else>
			{{ "on_site.menu.settings".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/settings.svg"/>
		</router-link>
    	<div class="spacer"></div>

		<a class="menu-button"
			v-if="route.name == 'profile'"
			:href="router.resolve({ name: 'profile'}).href"
			@click="closeMenuAndShowLoader('settings/setRequestingFlag')"
		>
			{{ "on_site.menu.profile".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/user.svg"/>
		</a>
		<router-link :to="{ name: 'profile' }" class="menu-button" v-else>
			{{ "on_site.menu.profile".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/user.svg"/>
		</router-link>
		<div class="spacer"></div>

		<router-link :to="{ name: 'help' }" class="menu-button">
			{{ "on_site.menu.help".trans() }}
			<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/question-mark.svg"/>
		</router-link>
		<div class="spacer"></div>

		<button class="loader light-blue logout-button" :class="{ 'is-Loading': isRequesting }" @click="logout">
			<span class="loader-container" v-html="loader"/>
			<span class="content">
				<img class="menu-button-icon" alt="Show pass..." src="../assets/icons/logout.svg"/>
				{{ 'on_site.menu.logout'.trans() }}
			</span>
		</button>
  	</div>
</template>

<script setup>
	import { ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter, useRoute } from 'vue-router'
	import { makeRequest } from '@/utils/makeRequest'
	import OneSignalHelper from '@/utils/OneSignalHelper'
	import { loader } from '@/utils/svgIcons'

	const store = useStore()
	const router = useRouter()
	const route = useRoute()

	const isRequesting = ref(false)
	const buttonPermissions = computed(() => store.state.user.buttonPermissions)


	// Logic for the logout button
	const logout = async () => {
		try {
			// Set flag
			isRequesting.value = true

			// Make request
			await makeRequest('get', 'api-logout')

			isRequesting.value = false

			// Unsubscribe user [When logged out, he won't recieve notifications]
			OneSignalHelper.unSubscribeUser()

			// Clear store values
			store.commit('userLoggedOut')
			store.commit('setAccessToken', '')
			store.commit('user/setUserData', null)
			store.commit('user/setSelectedCompany', null)
			store.commit('setLastAccessedRoute', '/dashboard')

			// Redirect to login
			router.push({ name:'login' })

		} catch(err) {
			isRequesting.value = false
			throw err
		}
	}


	function closeMenuAndShowLoader(loaderStorePath) {
		$('.menu-container').addClass('closed')

		store.commit(loaderStorePath, true)
	}
</script>

<style lang="sass" scoped>
.menu-container
	position: fixed
	top: 51px

	&.closed
		top: -100%
		opacity: 0
		visibility: hidden

.main-menu
	display: flex
	flex-direction: column
	width: 100%
	max-width: 900px
	min-width: 210px
	height: calc(100vh - 51px - 55px)
	padding-top: 13px
	padding-bottom: 130px
	background-color: $white
	opacity: 1
	transition: .5s
	overflow-y: auto
	box-shadow: 0 3px 6px #01000029
	z-index: 9998 // Higher than subHeader, lower than footer & header

	a
		text-decoration: none

	.spacer
		margin: 7px auto
		width: 88%
		height: 1px
		min-height: 1px
		background-color: $gray-blue

	.menu-button,
	.logout-button
		display: flex
		align-items: center
		margin: 0px auto
		border: none
		border-radius: 4px
		height: 50px
		min-height: 50px
		width: 88%
		padding-left: 11px
		text-align: left
		font-family: PoppinsMedium
		font-size: 16px
		box-sizing: border-box

	.menu-button
		justify-content: space-between
		padding-right: 15px
		background-color: $button-background-gray
		color: $text-dark-gray

	img
		height: 27px

	.logout-button
		justify-content: center
		background-color: $button-blue
		color: $white
		text-align: center

		.loader-container

		.content
			display: flex
			align-items: center
			justify-content: center

			img
				width: 25px
				height: auto

	img
		margin-right: 12.5px
		
.dark-mode
	.main-menu
		background-color: $dark-button-gray
		
		.menu-button
			background-color: $text-black
			color: $dark-button-text
</style>
