import { makeRequest } from "@/utils/makeRequest"

const state = {
    helpPageHTML: '',
    isRequesting: false,
    fetchedDashboardHelp: false
}

const getters = {

}

const actions = {
    fetchHelpPageHTML: async ({ commit, state }, routeName) => {
        commit('setRequestingFlag', true)

        try {
            const response = await makeRequest('get', 'api_help_page', { 'url': routeName })
            const { data } = response

            commit('sethelpPageHTML', data)
            commit('setRequestingFlag', false)

            if (!state.fetchedDashboardHelp) {
                commit('setFetchedDashboardHelp', true)
            }

        } catch(err) {
            commit('setRequestingFlag', false)
            throw err
        }
    }
}

const mutations = {
    sethelpPageHTML: (state, data) => {
        state.helpPageHTML = data.content
    },
    setFetchedDashboardHelp: (state, data) => {
        state.fetchedDashboardHelp = data
    },
    setRequestingFlag: (state, data) => {
        state.isRequesting = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}