import { createApp } from 'vue'
import OneSignalVue from 'onesignal-vue-3'
import OneSignalHelper from '@/utils/OneSignalHelper'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// jQuery and Axios
window.$ = require('jquery')
window.axios = require('axios')

// Access to PHP routes through Routing.generate()
import Routing from '../../public/js/router'
let routes = require('../../public/js/fos_js_routes.json')

routes.host = process.env.VUE_APP_DOMAIN_COCKPIT
routes.scheme = process.env.VUE_APP_DOMAIN_COCKPIT_SCHEME

Routing.setRoutingData(routes)
window.Routing = Routing

// Fetch options
const oneSignalOptions = OneSignalHelper.getInitOptions()

const app = createApp(App)
app.use(OneSignalVue, oneSignalOptions).use(store).use(router)
app.mount('#app')

// Custom directive
app.directive('click-outside', {
    beforeMount: function (el, binding) {
      	el.clickOutsideEvent = function (event) {
			// Check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {

				//Call method provided in attribute value
				binding.value()
			}
		}
		document.body.addEventListener('click', el.clickOutsideEvent)
    },
    beforeUnmount: function (el) {
      	document.body.removeEventListener('click', el.clickOutsideEvent)
    },
})
